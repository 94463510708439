export class LstAcctEnumCol {

	public static readonly NAME = new LstAcctEnumCol("name");
	public static readonly ACCT_TYPE_NAME = new LstAcctEnumCol("acct_type_name");
	public static readonly OB = new LstAcctEnumCol("ob");
	public static readonly CREATED_BY = new LstAcctEnumCol("created_by");
	public static readonly CREATED_AT = new LstAcctEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstAcctEnumCol[] {
		return [
			this.NAME,
			this.ACCT_TYPE_NAME,
			this.OB,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstAcctEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
