import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BASE_URL, TOKEN } from "../config";
import { GetAcctsObjectTypeEnum } from "../enums/GetAcctsObjectTypeEnum";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import { ListAcctForCustomerResType } from "../types/res/list/ListAcctForCustomerResType";

interface CustomerSelectDialogProps {
	showDialog: boolean,
	setShowDialog: React.Dispatch<React.SetStateAction<boolean>>,
	setAcctForCustomer: (acctForCustomerSelected: ListAcctForCustomerResType | null) => void
}

function CustomerSelectDialog({
	showDialog,
	setShowDialog,
	setAcctForCustomer
}: CustomerSelectDialogProps): JSX.Element {

	const [textToSearch, setTextToSearch] = useState<string>("");

	const [selectedAcctForCustomer, setSelectedAcctForCustomer] = useState<ListAcctForCustomerResType | null>(null);

	const [accounts, setAccounts] = useState<ListAcctForCustomerResType[]>([]);
	const [filteredAccounts, setFilteredAccounts] = useState<ListAcctForCustomerResType[]>([]);

	useEffect(() => {
		async function process() {

			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Bearer " + TOKEN.val,
					"Content-Type": "application/json",
				},
			};
			const res = await fetch(
				strFormat("%s/%s", BASE_URL, ResourceEnum.ACCT.name)
				+ getQueryString({ returnObjectType: GetAcctsObjectTypeEnum.ACCT_LIST_FOR_CUSTOMER_HDTO.enumName.toUpperCase() }),
				requestOptions);

			const results: ListAcctForCustomerResType[] = await res.json();
			setAccounts(results);
			setFilteredAccounts(results);
		}
		process();
	}, []);

	const handleClose = () => setShowDialog(false);

	const handleOk = (): void => {
		setAcctForCustomer(selectedAcctForCustomer);
		setShowDialog(false);
	};

	const getFilteredAccts = (textToSearch: string): ListAcctForCustomerResType[] => {
		let res: ListAcctForCustomerResType[] = [];
		for (let i = 0; i < accounts.length; i++) {
			let acct = accounts[i];
			let tempStr = "";
			if (acct.name !== null && acct.name !== undefined) {
				tempStr = tempStr + acct.name;
			}
			if (acct.address !== null && acct.address !== undefined) {
				tempStr = tempStr + acct.address;
			}
			if (acct.city !== null && acct.city !== undefined) {
				tempStr = tempStr + acct.city;
			}
			if (acct.stateName !== null && acct.stateName !== undefined) {
				tempStr = tempStr + acct.stateName;
			}
			if (acct.pin !== null && acct.pin !== undefined) {
				tempStr = tempStr + acct.pin;
			}
			if (acct.gstNo !== null && acct.gstNo !== undefined) {
				tempStr = tempStr + acct.gstNo;
			}
			if (tempStr.toLowerCase().includes(textToSearch.toLowerCase())
			) {
				res.push(acct);
			}
		}
		return res;
	};

	return (
		<Modal
			show={showDialog}
			onHide={handleClose}
			className="modal-xl"
			scrollable={true}
		>
			<Modal.Header closeButton>
				<Modal.Title>Customers</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form>
					<div className="mb-3">
						<input
							type="text"
							className="form-control"
							value={textToSearch}
							onChange={((e) => {
								setTextToSearch(e.target.value);
								let tempVal = getFilteredAccts(e.target.value);
								setFilteredAccounts(tempVal);
								setSelectedAcctForCustomer(tempVal[0]);
							})}
						/>
					</div>
				</form>

				<div className="flex-grow-1 mb-2 table-responsive text-nowrap">
					<table
						id="navigate"
						className="table table-hover table-bordered table-sm overflow-x-auto m-0 fixTableHead"
					>
						<thead className="bg-body-tertiary">
							<tr>
								<th scope="col"></th>
								<th scope="col">Sr. No.</th>
								<th scope="col">Name</th>
								<th scope="col">Alias</th>
								<th scope="col">Address</th>
								<th scope="col">City</th>
								<th scope="col">State</th>
								<th scope="col">PIN</th>
								<th scope="col">GST No.</th>
							</tr>
						</thead>
						<tbody className="">
							{filteredAccounts.map((account, i) => (
								<tr key={i} onClick={(e) => setSelectedAcctForCustomer(account)}
								>
									<td tabIndex={0}>
										<div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													name="rdoCustomer"
													checked={selectedAcctForCustomer === account}
													onChange={(e) => setSelectedAcctForCustomer(account)}
												/>
											</div>
										</div>
									</td>
									<td tabIndex={0}>
										<div>{i + 1}</div>
									</td>
									<td tabIndex={0}><div>{account.name}</div></td>
									<td tabIndex={0}><div>{account.nameToPrint}</div></td>
									<td tabIndex={0}><div>{account.address}</div></td>
									<td tabIndex={0}><div>{account.city}</div></td>
									<td tabIndex={0}><div>{account.stateName}</div></td>
									<td tabIndex={0}><div>{account.pin}</div></td>
									<td tabIndex={0}><div>{account.gstNo}</div></td>
								</tr>
							))}
						</tbody>
						<tfoot className="bg-body-tertiary">
							<tr>
								<th scope="row">Total</th>
								<th>{accounts.length}</th>
								{[...Array(7)].map((x, i) => (
									<th key={i}></th>
								))}
							</tr>
						</tfoot>
					</table>
				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleOk}>
					Ok
				</Button>
			</Modal.Footer>
		</Modal >
	);
}

export default CustomerSelectDialog;
