import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { ListCompanyProfileResType } from "../types/res/list/ListCompanyProfileResType";

export default class CompanyProfileApi {
	static async getCompanyProfiles() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.COMPANY_PROFILE.name), requestOptions);
		const listCompanyProfile: ListCompanyProfileResType[] = await res.json();
		return listCompanyProfile
	}
}