import React, { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import CompanyProfileApi from "../../api/CompanyProfileApi";
import EstimateStatusApi from "../../api/EstimateStatusApi";
import ProductApi from "../../api/ProductApi";
import TaxApi from "../../api/TaxApi";
import TaxCatApi from "../../api/TaxCatApi";
import TransporterApi from "../../api/TransporterApi";
import UnitApi from "../../api/UnitApi";
import VouOthChApi from "../../api/VouOthChApi";
import { BASE_URL, TOKEN } from "../../config";
import CustomerSelectDialog from "../../dialogs/CustomerSelectDialog";
import { CalcOnTypeEnum } from "../../enums/CalcOnTypeEnum";
import { ResourceEnum } from "../../enums/ResourceEnum";
import { VoucherTypeEnum } from "../../enums/VoucherTypeEnum";
import AcctJournalSumHelper from "../../helpers/AcctJournalSumHelper";
import CompanyProfileHelper from "../../helpers/CompanyProfileHelper";
import EstimateHelper from "../../helpers/EstimateHelper";
import EstimateItemHelper from "../../helpers/EstimateItemHelper";
import EstimateStatusHelper from "../../helpers/EstimateStatusHelper";
import ProductHelper from "../../helpers/ProductHelper";
import strFormat from "../../helpers/StringHelper";
import TransporterHelper from "../../helpers/TransporterHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import DropDownItemType from "../../types/DropDownItemType";
import { EstimateItemType } from "../../types/EstimateItemType";
import { ListAcctForCustomerResType } from "../../types/res/list/ListAcctForCustomerResType";
import { ListAcctResType } from "../../types/res/list/ListAcctResType";
import { ListCompanyProfileResType } from "../../types/res/list/ListCompanyProfileResType";
import { ListCustomerResType } from "../../types/res/list/ListCustomerResType";
import { ListEstimateStatusResType } from "../../types/res/list/ListEstimateStatusResType";
import { ListProdForEsimateResType } from "../../types/res/list/ListProdForEsimateResType";
import { ListTaxCatResType } from "../../types/res/list/ListTaxCatResType";
import { ListTaxResType } from "../../types/res/list/ListTaxResType";
import { ListTransporterResType } from "../../types/res/list/ListTransporterResType";
import { ListUnitResType } from "../../types/res/list/ListUnitResType";
import { ListVouOthChargeResType } from "../../types/res/list/ListVouOthChargeResType";
import { NextNoResType } from "../../types/res/NextNoResType";
import { VouOthChNonTaxableType } from "../../types/VouOthChNonTaxableType";
import { VouOthChOnItemType } from "../../types/VouOthChOnItemType";
import DownshiftSelect from "../DownshiftSelect";
import AddEstimateItemFormSv from "./AddEstimateItemFormSv";
import AddEstimateItemsTable from "./AddEstimateItemsTable";
import AddEstimateOthChNonTaxableSv from "./AddEstimateOthChNonTaxableSv";
import AddEstimateOthChOnItemSv from "./AddEstimateOthChOnItemSv";
import AddEstimateTaxSv from "./AddEstimateTaxSv";
import AddEstimateTotalSv from "./AddEstimateTotalSv";

interface AddEstimateProps {
	id?: number
}

function AddEstimate({
	id
}: AddEstimateProps): JSX.Element {

	const resourceName = ResourceEnum.ESTIMATE.name;

	const [acctForCustomer, setAcctForCustomer] = useState<ListAcctForCustomerResType | null>(null);

	const [no, setNo] = useState("");
	const [date, setDate] = useState<Date | null>(new Date());
	const [customerId, setCustomerId] = useState<number>(-1);
	const [stateId, setStateId] = useState<number>(-1);
	const [companyProfileId, setCompanyProfileId] = useState<string>("");
	const [transporterId, setTransporterId] = useState<string>("");
	const [estimateStatusId, setEstimateStatusId] = useState<string>("");
	const [lockStatusForDays, setLockStatusForDays] = useState<string>("");

	const [creditDaysLimit, setCreditDaysLimit] = useState<string>("");
	const [creditDaysCurrentStatus, setCreditDaysCurrentStatus] = useState<string>("");
	const [overdueBal, setOverdueBal] = useState<string>("");
	const [isCreditDaysRight, setIsCreditDaysRight] = useState<boolean>(true);

	const [creditAmt, setCreditAmt] = useState<string>("");
	const [currentBal, setCurrentBal] = useState<string>("");
	const [currentBalDiff, setCurrentBalDiff] = useState<string>("");
	const [isCreditAmtRight, setIsCreditAmtRight] = useState<boolean>(true);

	const [estimateItems, setEstimateItems] = useState<EstimateItemType[]>([]);
	const [estimateOthChOnItemArr, setEstimateOthChOnItemArr] = useState<VouOthChOnItemType[]>([]);

	const [isAddTax, setIsAddTax] = useState<boolean>(true);
	const [isIntra, setIsIntra] = useState<boolean>(true);

	const [estimateOthChNonTaxableArr, setEstimateOthChNonTaxableArr] = useState<VouOthChNonTaxableType[]>([]);
	//
	const [customers, setCustomers] = useState<ListCustomerResType[]>([]);

	const [companyProfiles, setCompanyProfiles] = useState<ListCompanyProfileResType[]>([]);
	const [companyProfilesForDd, setCompanyProfilesForDd] = useState<DropDownItemType[]>([]);

	const [taxMap, setTaxMap] = useState<Map<number, ListTaxResType>>(new Map());
	const [taxs, setTaxs] = useState<ListTaxResType[]>([]);

	const [taxCatMap, setTaxCatMap] = useState<Map<number, ListTaxCatResType>>(new Map());
	const [taxCats, setTaxCats] = useState<ListTaxCatResType[]>([]);

	const [transporters, setTransporters] = useState<ListTransporterResType[]>([]);
	const [transportersForDd, setTransportersForDd] = useState<DropDownItemType[]>([]);

	const [vouOthChOnItemResMap, setVouOthChOnItemResMap] = useState<Map<number, ListVouOthChargeResType>>(new Map());
	const [vouOthChOnItemResArr, setVouOthChOnItemResArr] = useState<ListVouOthChargeResType[]>([]);
	const [vouOthChOnItemResArrForDd, setVouOthChOnItemResArrForDd] = useState<DropDownItemType[]>([]);

	const [vouOthChNonTaxableResMap, setVouOthChNonTaxableResMap] = useState<Map<number, ListVouOthChargeResType>>(new Map());
	const [vouOthChNonTaxableResArr, setVouOthChNonTaxableResArr] = useState<ListVouOthChargeResType[]>([]);
	const [vouOthChNonTaxableResForDd, setVouOthChNonTaxableResForDd] = useState<DropDownItemType[]>([]);

	const [estimateStatuses, setEstimateStatuses] = useState<ListEstimateStatusResType[]>([]);
	const [estimateStatusesForDd, setEstimateStatusesForDd] = useState<DropDownItemType[]>([]);

	const [productsMap, setProductsMap] = useState<Map<number, ListProdForEsimateResType>>(new Map());
	const [products, setProducts] = useState<ListProdForEsimateResType[]>([]);
	const [productsForDd, setProductsForDd] = useState<DropDownItemType[]>([]);

	const [unitsMap, setUnitsMap] = useState<Map<number, ListUnitResType>>(new Map());
	const [units, setUnits] = useState<ListUnitResType[]>([]);


	const [accounts, setAccounts] = useState<ListAcctResType[]>([]);
	//
	const [showCustomerDialog, setShowCustomerDialog] = useState(false);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			CompanyProfileApi.getCompanyProfiles().then((listCompanyProfiles) => {
				setCompanyProfiles(listCompanyProfiles);
				setCompanyProfilesForDd(CompanyProfileHelper.getCompanyProfilesForDd(listCompanyProfiles));
			});

			TaxApi.getTaxs().then((listTaxs) => {
				setTaxs(listTaxs);
				let taxMapLoc = new Map<number, ListTaxResType>([]);
				for (let index = 0; index < listTaxs.length; index++) {
					const tax = listTaxs[index];
					taxMapLoc.set(tax.id, tax);
				}
				setTaxMap(taxMapLoc);
			});

			TaxCatApi.getTaxCats().then((listTaxCats) => {
				setTaxCats(listTaxCats);

				let taxCatMapLoc = new Map<number, ListTaxCatResType>([]);
				for (let index = 0; index < listTaxCats.length; index++) {
					const taxCat = listTaxCats[index];
					taxCatMapLoc.set(taxCat.id, taxCat);
				}
				setTaxCatMap(taxCatMapLoc);
			});

			TransporterApi.getTransporters().then((listTransporters) => {
				setTransporters(listTransporters);
				setTransportersForDd(TransporterHelper.getTransportersForDd(listTransporters));
			});

			VouOthChApi.getVouOthCh(CalcOnTypeEnum.ITEM, false).then((vouOthChOnItemArray) => {
				setVouOthChOnItemResArr(vouOthChOnItemArray);
				setVouOthChOnItemResArrForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChOnItemArray));

				let vouOthChOnItemMapLoc = new Map<number, ListVouOthChargeResType>([]);
				for (let index = 0; index < vouOthChOnItemArray.length; index++) {
					const vouOthChOnItem = vouOthChOnItemArray[index];
					vouOthChOnItemMapLoc.set(vouOthChOnItem.id, vouOthChOnItem);
				}
				setVouOthChOnItemResMap(vouOthChOnItemMapLoc);
			});

			VouOthChApi.getVouOthCh(CalcOnTypeEnum.VOUCHER, false).then((vouOthChNonTaxableResArr) => {
				setVouOthChNonTaxableResArr(vouOthChNonTaxableResArr);
				setVouOthChNonTaxableResForDd(VouOthChHelper.getVouOthChArrForDd(vouOthChNonTaxableResArr));

				let vouOthChNonTaxableMapLoc = new Map<number, ListVouOthChargeResType>([]);
				for (let index = 0; index < vouOthChNonTaxableResArr.length; index++) {
					const vouOthChOnItem = vouOthChNonTaxableResArr[index];
					vouOthChNonTaxableMapLoc.set(vouOthChOnItem.id, vouOthChOnItem);
				}
				setVouOthChNonTaxableResMap(vouOthChNonTaxableMapLoc);
			});

			EstimateStatusApi.getEstimateStatuses().then((estimateStatuses) => {
				setEstimateStatuses(estimateStatuses);
				setEstimateStatusesForDd(EstimateStatusHelper.getEstimateStatusForDd(estimateStatuses));
			});

			ProductApi.getProdForEstimate().then((listProducts) => {

				let productMapLoc = new Map<number, ListProdForEsimateResType>([]);
				for (let index = 0; index < listProducts.length; index++) {
					const product = listProducts[index];
					productMapLoc.set(product.id, product);
				}

				setProductsMap(productMapLoc);
				setProducts(listProducts);
				setProductsForDd(ProductHelper.getProductsForDd(listProducts));

			});

			UnitApi.getUnits().then((listUnits) => {

				let unitMapLoc = new Map<number, ListUnitResType>([]);
				for (let index = 0; index < listUnits.length; index++) {
					const unit = listUnits[index];
					unitMapLoc.set(unit.id, unit);
				}
				setUnitsMap(unitMapLoc);
				setUnits(listUnits);

			});

			if (id !== undefined && id !== null) {

				const requestOptions = {
					method: "GET",
					headers: {
						Authorization: "Bearer " + TOKEN.val,
						"Content-Type": "application/json",
					},
				};
				const res = await fetch(BASE_URL + "transporters/" + id, requestOptions);
				//const result: EstimateResType = await res.json();
				//fillInUi(result);
			}
			else {
				const requestOptions = {
					method: "GET",
					headers: {
						Authorization: "Bearer " + TOKEN.val,
						"Content-Type": "application/json",
					},
				};
				const res = await fetch(
					strFormat("%s/%s/%s", BASE_URL, ResourceEnum.VOUCHER.name, "next-no")
					+ getQueryString({ voucherType: VoucherTypeEnum.SALES.enumName.toUpperCase() }),
					requestOptions);

				if (res.status !== 200) {
					setMessageDialogMessage("Server error");
					setShowMessageDialog(true);
				}
				else {
					const data: NextNoResType = await res.json();
					setNo(data.no);
				}

			}

		}
		process();
	}, []);

	const handleItemChange = (addEstimateItem: EstimateItemType) => {
		setEstimateItems(EstimateItemHelper.calItems(isAddTax, isIntra, taxMap, taxCatMap, unitsMap, productsMap, [...estimateItems, addEstimateItem], estimateOthChOnItemArr));
	}

	const handleOthChOnItemChange = (estimateOthChOnItemArr: VouOthChOnItemType[]) => {
		setEstimateItems(EstimateItemHelper.calItems(isAddTax, isIntra, taxMap, taxCatMap, unitsMap, productsMap, estimateItems, estimateOthChOnItemArr));
		setEstimateOthChOnItemArr(estimateOthChOnItemArr);
	}

	const handleOthChNonTaxableChange = (estimateOthChNonTaxableArr: VouOthChNonTaxableType[]) => {
		EstimateHelper.calOthChNonTax(estimateItems, estimateOthChNonTaxableArr);
		setEstimateOthChNonTaxableArr(estimateOthChNonTaxableArr);
	}

	const handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (id === undefined || id === null) {

			const requestOptions = {
				method: "POST",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				},
				body: JSON.stringify("")
			}

			const res = await fetch(BASE_URL + "transporters", requestOptions);

			if (res.status !== 201) {
				const data = await res.json();
				setMessageDialogMessage(data.message);
				setShowMessageDialog(true);
			}
			else {
				setMessageDialogMessage("Saved");
				setShowMessageDialog(true);
				clearBoxes();
			}
		}

	}

	const fillInUi = (/*transporterResType: EstimateResType*/) => {
		setNo("");
		setDate(new Date());
		setCustomerId(-1);
		setCompanyProfileId("");
		setTransporterId("");
		setEstimateStatusId("");
		setLockStatusForDays("");


		setCreditDaysLimit("");
		setCreditDaysCurrentStatus("");
		setOverdueBal("");

		setCreditAmt("");
		setCurrentBal("");
		setCurrentBalDiff("");
	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {

		setNo("");
		setDate(new Date());
		setCustomerId(-1);
		setCompanyProfileId("");
		setTransporterId("");
		setEstimateStatusId("");
		setLockStatusForDays("");


		setCreditDaysLimit("");
		setCreditDaysCurrentStatus("");
		setOverdueBal("");

		setCreditAmt("");
		setCurrentBal("");
		setCurrentBalDiff("");
	};


	const mySetAcctForCustomer = (acctForCustomerSelected: ListAcctForCustomerResType | null) => {

		let creditDaysLimitLoc = "";
		let creditDaysCurrentStatusLoc = "";
		let overdueBalLoc = "";
		let isCreditDaysRightLoc = false;

		let creditAmtLoc = "";
		let currentBalLoc = "";
		let currenBalDiffLoc = "";
		let isCreditAmtRightLoc = false;

		if (acctForCustomerSelected !== null) {

			setIsIntra(13 === acctForCustomerSelected.stateId)

			if (acctForCustomerSelected.creditDays !== undefined
				&& acctForCustomerSelected.creditDays !== null) {
				creditDaysLimitLoc = acctForCustomerSelected.creditDays.toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.overdueDays !== undefined
				&& acctForCustomerSelected.overdueDays !== null
			) {
				creditDaysCurrentStatusLoc = acctForCustomerSelected.overdueDays.toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.overdueAmt !== undefined
				&& acctForCustomerSelected.overdueAmt !== null
				&& acctForCustomerSelected.overdueAmt !== 0
			) {
				overdueBalLoc = acctForCustomerSelected.overdueAmt.toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.overdueAmt !== undefined
				&& acctForCustomerSelected.overdueAmt !== null
				&& acctForCustomerSelected.overdueAmt <= 0
			) {
				isCreditDaysRightLoc = true;
			}

			//

			if (acctForCustomerSelected.creditAmtLimit !== undefined
				&& acctForCustomerSelected.creditAmtLimit !== null

			) {
				creditAmtLoc = acctForCustomerSelected.creditAmtLimit.toLocaleString('en-IN');
			}

			let acctJournalSumHelper = new AcctJournalSumHelper(acctForCustomerSelected.sumDebit,
				acctForCustomerSelected.sumCredit);

			if (acctJournalSumHelper.isCrDrSame()) {
				currentBalLoc = "0";
			}
			else if (acctJournalSumHelper.isCrBig()) {
				currentBalLoc = " - " + acctJournalSumHelper.getCrBal().toLocaleString('en-IN');
			}
			else if (acctJournalSumHelper.isDrBig()) {
				currentBalLoc = acctJournalSumHelper.getDrBal().toLocaleString('en-IN');
			}

			if (acctForCustomerSelected.creditAmtLimit !== null) {
				acctJournalSumHelper.credit = acctJournalSumHelper.credit + acctForCustomerSelected.creditAmtLimit;
			}

			let acctJournalSumHelperTemp = new AcctJournalSumHelper(
				acctJournalSumHelper.debit,
				acctJournalSumHelper.credit
			);

			currenBalDiffLoc = acctJournalSumHelperTemp.getCrBal().toLocaleString('en-IN');

			if (acctJournalSumHelperTemp.getCrBal() >= 0) {
				isCreditAmtRightLoc = true;
			}

		}

		setCreditDaysLimit(creditDaysLimitLoc);
		setCreditDaysCurrentStatus(creditDaysCurrentStatusLoc);
		setOverdueBal(overdueBalLoc);
		setIsCreditDaysRight(isCreditDaysRightLoc);

		setCreditAmt(creditAmtLoc);
		setCurrentBal(currentBalLoc);
		setCurrentBalDiff(currenBalDiffLoc);
		setIsCreditAmtRight(isCreditAmtRightLoc);

		setAcctForCustomer(acctForCustomerSelected)
	}

	return (
		<>
			<div className="container-fluid">
				<form onSubmit={(event) => handleClickSave(event)}>

					<div className="row col-sm-6">
						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtNo" className="col-form-label">Name</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtNo"
									type="text"
									className="form-control"
									value={no}
									onChange={(e) => setNo(e.target.value)}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="dpDate" className="col-form-label">Date</label>
							</div>
							<div className="col-sm-10">
								<DatePicker
									id="dpDate"
									dateFormat="dd-MM-yyyy"
									selected={date}
									onChange={(e) => setDate(e)}
									className="form-control"
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCustomer" className="col-form-label">Customer</label>
							</div>
							<div className="col-sm-10">
								<div className="input-group">
									<input
										id="txtCustomer"
										type="text"
										className="form-control"
										value={acctForCustomer?.name}
										readOnly={true}
									/>
									<button
										className="btn btn-outline-secondary" type="button"
										onClick={(e) => setShowCustomerDialog(true)}
									>...</button>
								</div>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtAddress" className="col-form-label">Address</label>
							</div>
							<div className="col-sm-10">
								<textarea
									id="txtAddress"
									className="form-control"
									rows={3}
									value={acctForCustomer?.address}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtCity" className="col-form-label">City</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtCity"
									type="text"
									className="form-control"
									value={acctForCustomer?.city}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label className="col-form-label">State</label>
							</div>
							<div className="col-sm-10">
								<input
									type="text"
									className="form-control"
									value={acctForCustomer?.stateName}
									readOnly={true}
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbCompanyProfile" className="col-form-label">Company Profile</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbCompanyProfile"}
									value={companyProfileId}
									onChange={setCompanyProfileId}
									options={companyProfilesForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbTransporter" className="col-form-label">Transporter</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbTransporter"}
									value={transporterId}
									onChange={setTransporterId}
									options={transportersForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="cmbEstimateStatusId" className="col-form-label">Status</label>
							</div>
							<div className="col-sm-10">
								<DownshiftSelect
									inputId={"cmbEstimateStatusId"}
									value={estimateStatusId}
									onChange={setEstimateStatusId}
									options={estimateStatusesForDd}
									isLoading={false}
									isDisabled={false}
									placeholder=""
								/>
							</div>
						</div>

						<div className="row align-items-center mb-3">
							<div className="col-sm-2">
								<label htmlFor="txtLockStockForDays" className="col-form-label">Lock Stock for Days</label>
							</div>
							<div className="col-sm-10">
								<input
									id="txtLockStockForDays"
									type="text"
									className="form-control"
									onChange={(e) => setLockStatusForDays(e.target.value)}
									value={lockStatusForDays}
								/>
							</div>
						</div>

					</div>

					<div className="row mb-2">
						<div className="col-md-2">
							&nbsp;
						</div>
						<div className="col-md-3">
							Limit
						</div>
						<div className="col-md-3">
							Current Status
						</div>
						<div className="col-md-3">
							Balance
						</div>
						<div className="col-md-3">
							&nbsp;
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-2">
							Credit Days
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={creditDaysLimit}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={creditDaysCurrentStatus}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={overdueBal}
								readOnly={true}
							/>
						</div>
						<div className="col-md-1">
							{isCreditDaysRight ? "" : "X"}
						</div>
					</div>
					<div className="row mb-2">
						<div className="col-md-2">
							Credit Amount
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={creditAmt}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={currentBal}
								readOnly={true}
							/>
						</div>
						<div className="col-md-3">
							<input
								type="text"
								className="form-control"
								value={currentBalDiff}
								readOnly={true}
							/>
						</div>
						<div className="col-md-1">
							{isCreditAmtRight ? "" : "X"}
						</div>
					</div>

				</form>

				<Tabs
					defaultActiveKey="tab-item-details"
					id="uncontrolled-tab-example"
					className="mb-3"
				>
					<Tab eventKey="tab-item-details" title="Item Details">
						<AddEstimateItemFormSv
							products={products}
							productsForDd={productsForDd}
							productsMap={productsMap}
							units={units}
							unitsMap={unitsMap}
							taxMap={taxMap}
							taxCatMap={taxCatMap}
							handleItemChange={handleItemChange}
						/>

						<AddEstimateItemsTable
							taxMap={taxMap}
							taxCatMap={taxCatMap}
							addEstimateItems={estimateItems}
							productsMap={productsMap}
							unitsMap={unitsMap}
						/>
					</Tab>
					<Tab eventKey="tab-pending-items" title="Pending Items">
						Tab content for Profile
					</Tab>
				</Tabs>

				<hr />

				<div className="d-flex mb-3 g-3 justify-content-end">
					<div className="col-auto g-3">
						<label className="col-form-label">Total</label>
					</div>
					<div className="col-auto">
						<input
							value={EstimateHelper.getSumAmtAfterItemDisc(estimateItems)}
							type="text"
							className="form-control"
							readOnly={true}
						/>
					</div>
				</div>

				<hr />

				<AddEstimateOthChOnItemSv
					vouOthChOnItemResMap={vouOthChOnItemResMap}
					vouOthChOnItemResArrForDd={vouOthChOnItemResArrForDd}
					vouOthChOnItemArr={estimateOthChOnItemArr}
					handleOthChOnItemChange={handleOthChOnItemChange}
				/>

				<hr />

				<div className="d-flex mb-3 g-3 justify-content-end">
					<div className="col-auto g-3">
						<label className="col-form-label">Total</label>
					</div>
					<div className="col-auto">
						<input
							value={EstimateHelper.getSumItemTaxableAmt(estimateItems)}
							type="text"
							className="form-control"
							readOnly={true}
						/>
					</div>
				</div>

				<hr />

				<AddEstimateTaxSv
					isAddTax={isAddTax}
					setIsAddTax={setIsAddTax}
					isIntra={isIntra}
					taxAmtContainer={EstimateHelper.getTaxSum(estimateItems)}
				/>

				<hr />

				<AddEstimateOthChNonTaxableSv
					vouOthChNonTaxableResMap={vouOthChNonTaxableResMap}
					vouOthChNonTaxableResArrForDd={vouOthChNonTaxableResForDd}
					vouOthChNonTaxableArr={estimateOthChNonTaxableArr}
					handleOthChNonTaxableChange={handleOthChNonTaxableChange}
				/>

				<hr />

				<AddEstimateTotalSv
					totalAmtContainer={EstimateHelper.getTotalAmt(estimateItems, estimateOthChNonTaxableArr)}
				/>

				<button type="submit" className="btn btn-primary">Submit</button>
			</div >

			<Modal show={showMessageDialog} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Message</Modal.Title>
				</Modal.Header>
				<Modal.Body>{messageDialogMessage}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						OK
					</Button>
				</Modal.Footer>
			</Modal>


			<CustomerSelectDialog
				showDialog={showCustomerDialog}
				setShowDialog={setShowCustomerDialog}
				setAcctForCustomer={mySetAcctForCustomer}
			/>

		</>
	);
}

export default AddEstimate;
