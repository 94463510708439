export class LstCdpJournalVouEnumCol {

	public static readonly NO = new LstCdpJournalVouEnumCol("no");
	public static readonly DATE = new LstCdpJournalVouEnumCol("date");
	public static readonly CUSTOMER = new LstCdpJournalVouEnumCol("customer");
	public static readonly CDP = new LstCdpJournalVouEnumCol("cdp");
	public static readonly NOTE = new LstCdpJournalVouEnumCol("note");
	public static readonly AMT = new LstCdpJournalVouEnumCol("amt");
	public static readonly CREATED_BY = new LstCdpJournalVouEnumCol("created_by");
	public static readonly CREATED_AT = new LstCdpJournalVouEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstCdpJournalVouEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.CUSTOMER,
			this.CDP,
			this.NOTE,
			this.AMT,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstCdpJournalVouEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
