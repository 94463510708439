import { ListTaxResType } from "../types/res/list/ListTaxResType";
import { TaxAmtContainerType } from "../types/TaxAmtContainerType";

export default class TaxHelper {

	static calTaxAmt(
		tax: ListTaxResType,
		taxRate: number,
		isIntra: boolean,
		taxableAmt: number
	): TaxAmtContainerType {

		let cgstA = 0;
		let sgstA = 0;
		let igstA = 0;
		let amtWithTax = 0;

		if (tax !== undefined && tax !== null && taxableAmt !== undefined && taxableAmt !== null) {

			if (taxRate === undefined || taxRate === null) {
				taxRate = tax.igst;
			}

			let taxAmt = taxableAmt * taxRate / 100;

			if (isIntra) {
				cgstA = taxAmt / 2;
				sgstA = cgstA;
			}
			else {
				igstA = taxAmt;
			}

			amtWithTax = taxableAmt + cgstA + sgstA + igstA;
		}

		return {
			cgstA: cgstA,
			sgstA: sgstA,
			igstA: igstA,
			amtWithTax: amtWithTax
		}

	}

}