export class LstPiEnumCol {

	public static readonly NO = new LstPiEnumCol("no");
	public static readonly DATE = new LstPiEnumCol("date");
	public static readonly CUSTOMER_NAME = new LstPiEnumCol("customer_name");
	public static readonly CITY = new LstPiEnumCol("city");
	public static readonly STATE_NAME = new LstPiEnumCol("state_name");
	public static readonly AGENT_NAME = new LstPiEnumCol("agent_name");
	public static readonly AMT_PAYABLE = new LstPiEnumCol("amt_payable");
	public static readonly AMT_CLRD = new LstPiEnumCol("amt_clrd");
	public static readonly AMT_UNCLRD = new LstPiEnumCol("amt_unclrd");
	public static readonly NOTE = new LstPiEnumCol("note");
	public static readonly CREATED_BY = new LstPiEnumCol("created_by");
	public static readonly CREATED_AT = new LstPiEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstPiEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.CUSTOMER_NAME,
			this.CITY,
			this.STATE_NAME,
			this.AGENT_NAME,
			this.AMT_PAYABLE,
			this.AMT_CLRD,
			this.AMT_UNCLRD,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstPiEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
