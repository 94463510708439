import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { ListTransporterResType } from "../types/res/list/ListTransporterResType";

export default class TransporterApi {
	static async getTransporters() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.TRANSPORTER.name), requestOptions);
		const listTransporter: ListTransporterResType[] = await res.json();
		return listTransporter
	}
}