export class LstCashDiscVouEnumCol {

	public static readonly NO = new LstCashDiscVouEnumCol("no");
	public static readonly DATE = new LstCashDiscVouEnumCol("date");
	public static readonly SALES_NO = new LstCashDiscVouEnumCol("sales_no");
	public static readonly SALES_DATE = new LstCashDiscVouEnumCol("sales_date");
	public static readonly CUSTOMER_NAME = new LstCashDiscVouEnumCol("customer_name");
	public static readonly RECEIPT_NO = new LstCashDiscVouEnumCol("receipt_no");
	public static readonly RECEIPT_DATE = new LstCashDiscVouEnumCol("receipt_date");
	public static readonly AMT_ITEM_VAL = new LstCashDiscVouEnumCol("amt_item_val");
	public static readonly AMT_SALES = new LstCashDiscVouEnumCol("amt_sales");
	public static readonly AMT_RCVD = new LstCashDiscVouEnumCol("amt_rcvd");
	public static readonly DAYS = new LstCashDiscVouEnumCol("days");
	public static readonly RATE = new LstCashDiscVouEnumCol("rate");
	public static readonly AMT_DISC = new LstCashDiscVouEnumCol("amt_disc");
	public static readonly DISC_GIVEN = new LstCashDiscVouEnumCol("disc_given");
	public static readonly DISC_PENDING = new LstCashDiscVouEnumCol("disc_pending");
	public static readonly CREATED_BY = new LstCashDiscVouEnumCol("created_by");
	public static readonly CREATED_AT = new LstCashDiscVouEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstCashDiscVouEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.SALES_NO,
			this.SALES_DATE,
			this.CUSTOMER_NAME,
			this.RECEIPT_NO,
			this.RECEIPT_DATE,
			this.AMT_ITEM_VAL,
			this.AMT_SALES,
			this.AMT_RCVD,
			this.DAYS,
			this.RATE,
			this.AMT_DISC,
			this.DISC_GIVEN,
			this.DISC_PENDING,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstCashDiscVouEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
