import { MenuResType } from "../types/res/MenuResType";
import MenuButtonOnly from "./MenuButtonOnly";
import MenuButtonWiArrow from "./MenuButtonWiArrow";
import MenuButtonWiPlus from "./MenuButtonWiPlus";

interface MenuButtonProps {
  menu: MenuResType;
  index: number;
  openMenuView: (parentMenu: MenuResType) => void;
  openListView: (menu: MenuResType) => void;
  openAddView: (menu: MenuResType) => void;
}

function MenuButton({
  menu,
  index,
  openMenuView,
  openListView,
  openAddView,
}: MenuButtonProps): JSX.Element {
  if (menu.systemMenu) {
    if (menu.hasAdd) {
      return (
        <MenuButtonWiPlus
          index={index}
          menu={menu}
          openListView={openListView}
          openAddView={openAddView}
        />
      );
    } else {
      return (
        <MenuButtonOnly index={index} menu={menu} onMenuClick={openListView} />
      );
    }
  }
  return (
    <MenuButtonWiArrow index={index} menu={menu} openMenuView={openMenuView} />
  );
}

export default MenuButton;
