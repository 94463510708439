export class TxTypeEnum {
	public static readonly DR = new TxTypeEnum(1, "Dr");
	public static readonly CR = new TxTypeEnum(2, "Cr");

	private constructor(
		public readonly id: number,
		public readonly name: string) {
	}

	static get values(): TxTypeEnum[] {
		return [
			this.DR,
			this.CR,
		];
	}

	static getById(id: number): TxTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

	static getByName(name: string): TxTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.name === name) {
				return element;
			}
		}
		return null;
	}

}
