import React, { createContext, ReactNode, useCallback, useContext, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

// Define the type for the data object
export interface ConfirmData {
	title?: string;
	message?: string;
}

// Define the context type based on the confirm function
type ConfirmContextType = (data: ConfirmData) => Promise<boolean>;

const ConfirmDialog = createContext<ConfirmContextType | null>(null);

export const ConfirmDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [showDialog, setShowDialog] = useState(false);
	const [state, setState] = useState<ConfirmData>({
		title: "Delete",
		message: "Delete?"
	});
	const fn = useRef<(choice: boolean) => void>();

	const confirm = useCallback((data: ConfirmData) => {
		return new Promise<boolean>((resolve) => {
			setState({ ...data });
			setShowDialog(true);

			fn.current = (choice) => {
				resolve(choice);
				setShowDialog(false);
			};
		});
	}, [state]);

	return (
		<ConfirmDialog.Provider value={confirm}>
			{children}
			{/* The Alert component is not included in the provided code, so make sure to include it here */}
			<Modal show={showDialog} onHide={() => fn.current!(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>{state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{state.message}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => fn.current!(false)}>
						CANCEL
					</Button>
					<Button variant="primary" onClick={() => fn.current!(true)}>
						DELETE
					</Button>
				</Modal.Footer>
			</Modal>
		</ConfirmDialog.Provider>
	);
}

export default function useConfirm() {
	return useContext(ConfirmDialog);
}
