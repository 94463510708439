import DropDownItemType from "../types/DropDownItemType";
import { ListCompanyProfileResType } from "../types/res/list/ListCompanyProfileResType";

export default class CompanyProfileHelper {

	static getCompanyProfilesForDd(listCompanyProfiles: ListCompanyProfileResType[]) {

		let finalArr: DropDownItemType[] = [];
		listCompanyProfiles.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

}