import DropDownItemType from "../types/DropDownItemType";
import { ListProdForEsimateResType } from "../types/res/list/ListProdForEsimateResType";
import { ListProductResType } from "../types/res/list/ListProductResType";

export default class ProductHelper {

	static getProductsForDd(listProducts: ListProductResType[]) {

		let finalArr: DropDownItemType[] = [];
		listProducts.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getProductForDd(product: ListProductResType | ListProdForEsimateResType): DropDownItemType {
		return {
			id: product.id,
			name: product.name
		};
	}

}