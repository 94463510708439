import { PriceListTypeEnum } from "../enums/PriceListTypeEnum";
import RateTypeEnum from "../enums/RateTypeEnum";
import { EstimateItemType } from "../types/EstimateItemType";
import { ListProdForEsimateResType } from "../types/res/list/ListProdForEsimateResType";
import { ListTaxCatResType } from "../types/res/list/ListTaxCatResType";
import { ListTaxResType } from "../types/res/list/ListTaxResType";
import { ListUnitResType } from "../types/res/list/ListUnitResType";
import { VouOthChOnItemType } from "../types/VouOthChOnItemType";
import TaxHelper from "./TaxHelper";
import UnitHelper from "./UnitHelper";

export default class EstimateItemHelper {

	static calItems(
		isAddTax: boolean,
		isIntra: boolean,
		taxMap: Map<number, ListTaxResType>,
		taxCatMap: Map<number, ListTaxCatResType>,
		unitMap: Map<number, ListUnitResType>,
		productMap: Map<number, ListProdForEsimateResType>,
		estimateItems: EstimateItemType[],
		estimateOthChOnItemArr: VouOthChOnItemType[],
	) {
		if (estimateItems !== undefined && estimateItems !== null && estimateItems.length > 0) {

			let sumAmtAfterDisc = 0;
			for (let i = 0; i < estimateItems.length; i++) {
				let estimateItem = estimateItems[i];

				let product = productMap.get(estimateItem.productId);

				let taxId: number;

				if (product?.taxCatId === null) {
					taxId = product.taxId;
				}
				else {
					let taxCat = taxCatMap.get(product?.taxCatId!);
					taxId = taxCat?.taxId!;
				}
				estimateItem.derivedTaxId = taxId;

				estimateItem.derivedQty = UnitHelper.convert(
					estimateItem.orderQty,
					unitMap.get(estimateItem.orderUnitId)!,
					unitMap.get(productMap.get(estimateItem.productId)?.unitId!)!
				);

				let tax = taxMap.get(estimateItem.derivedTaxId);

				if (estimateItem.priceListTypeId === PriceListTypeEnum.A.id) {
					estimateItem.derivedRate = Math.floor(estimateItem.priceListRate * 100 / (100 + tax!.igst) * 100) / 100;
				}
				else {
					estimateItem.derivedRate = estimateItem.priceListRate;
				}

				estimateItem.derivedQxr = estimateItem.derivedQty * estimateItem.derivedRate;

				if (estimateItem.discRate === undefined || estimateItem.discRate === null) {
					estimateItem.derivedDiscA = 0;
				}
				else {
					switch (estimateItem.discRateTypeId) {
						case RateTypeEnum.FLAT.id:
							estimateItem.derivedDiscA = estimateItem.discRate * estimateItem.derivedQty;
							break;
						case RateTypeEnum.PERCENT.id:
							estimateItem.derivedDiscA = estimateItem.discRate * estimateItem.derivedQxr / 100;
							break;
						default:
							break;
					}
				}

				estimateItem.derivedAmtAfterDisc = estimateItem.derivedQxr - estimateItem.derivedDiscA!;

				estimateItem.derivedTaxableAmt = estimateItem.derivedAmtAfterDisc;

				sumAmtAfterDisc = sumAmtAfterDisc + estimateItem.derivedAmtAfterDisc;

				if (isAddTax) {

					if (estimateItem.priceListTypeId === PriceListTypeEnum.A.id) {
						estimateItem.derivedTaxId = tax?.id;
						estimateItem.derivedTaxRate = tax?.igst;
					}
					else {
						estimateItem.derivedTaxId = 1;
						estimateItem.derivedTaxRate = 0;
					}

				}
			}



			if (estimateOthChOnItemArr !== undefined && estimateOthChOnItemArr !== null && estimateOthChOnItemArr.length > 0) {
				for (let i = 0; i < estimateOthChOnItemArr.length; i++) {
					let vouOthChOnItem = estimateOthChOnItemArr[i];

					vouOthChOnItem.derivedAmt = 0;

					for (let j = 0; j < estimateItems.length; j++) {
						let estimateItem = estimateItems[j];
						this.applyOthChOnItem(sumAmtAfterDisc, estimateItem, vouOthChOnItem);
					}
					if (vouOthChOnItem.rateTypeId === RateTypeEnum.FLAT.id) {
						vouOthChOnItem.derivedAmt = vouOthChOnItem.rate;
					}
				}
			}

			if (isAddTax) {
				for (let i = 0; i < estimateItems.length; i++) {
					let estimateItem = estimateItems[i];

					let taxAmtContainer = TaxHelper.calTaxAmt(
						taxMap.get(estimateItem.derivedTaxId!)!,
						estimateItem.derivedTaxRate!,
						isIntra,
						estimateItem.derivedTaxableAmt!
					)

					estimateItem.derivedCgstA = taxAmtContainer.cgstA;
					estimateItem.derivedSgstA = taxAmtContainer.sgstA;
					estimateItem.derivedIgstA = taxAmtContainer.igstA;
					estimateItem.derivedTaxAmt = taxAmtContainer.cgstA + taxAmtContainer.sgstA + taxAmtContainer.igstA;
					estimateItem.derivedAmt = taxAmtContainer.amtWithTax;
				}
			}



		}

		return estimateItems;
	}

	private static applyOthChOnItem(sumAmtAftDisc: number, estimateItem: EstimateItemType, vouOthChOnItem: VouOthChOnItemType) {

		let itemOthChAmt = 0;

		switch (vouOthChOnItem.rateTypeId) {
			case RateTypeEnum.FLAT.id:
				itemOthChAmt = vouOthChOnItem.rate
					* estimateItem.derivedAmtAfterDisc!
					/ sumAmtAftDisc;
				break;
			case RateTypeEnum.PERCENT.id:
				itemOthChAmt = estimateItem.derivedTaxableAmt!
					* vouOthChOnItem.rate
					/ 100;

				vouOthChOnItem.derivedAmt = vouOthChOnItem.derivedAmt! + itemOthChAmt;
				break;
		}

		estimateItem.derivedTaxableAmt = estimateItem.derivedTaxableAmt! + itemOthChAmt;
	}

}