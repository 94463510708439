import Cookies from "universal-cookie";
import { BASE_URL, MENUS, TOKEN } from "./config";
import strFormat from "./helpers/StringHelper";
import { MenuResType } from "./types/res/MenuResType";

export const checkIsLoggedIn = () => {
	const cookies = new Cookies();
	const token = cookies.get("token");

	if (typeof token === "undefined" || token === null || token === "") {
		return false;
	} else {
		console.log(token);
		TOKEN.val = token;
		return true;
	}
};

export const getMenus: () => Promise<MenuResType> = async () => {
	if (MENUS.val === null) {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, "menus"), requestOptions);
		const data = await res.json();
		MENUS.val = data;
	}

	return MENUS.val;
};
