export class LstCommncEnumCol {

	public static readonly PARENT_TYPE_ID = new LstCommncEnumCol("parent_type_id", "Parent");
	public static readonly NAME = new LstCommncEnumCol("name", "Name");
	public static readonly IS_ON = new LstCommncEnumCol("is_on", "On/End");
	public static readonly LAST_REMARK = new LstCommncEnumCol("last_remark", "Last Remark");
	public static readonly LAST_REMARK_DATE = new LstCommncEnumCol("last_remark_date", "Last Remark Date");
	public static readonly CREATED_BY_USER_NAME = new LstCommncEnumCol("created_by_user_name", "Created By");
	public static readonly CREATED_AT = new LstCommncEnumCol("created_at", "Created At");

	private constructor(public readonly key: string, public readonly name: string) {
		
	}

	static get values(): LstCommncEnumCol[] {
		return [
			this.PARENT_TYPE_ID,
			this.NAME,
			this.IS_ON,
			this.LAST_REMARK,
			this.LAST_REMARK_DATE,
			this.CREATED_BY_USER_NAME,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstCommncEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
