import React, { useEffect, useState } from "react";
import RateTypeEnum from "../../enums/RateTypeEnum";
import StringHelper2 from "../../helpers/StringHelper2";
import DropDownItemType from "../../types/DropDownItemType";
import { EstimateOthChOnItemRowHolder } from "../../types/EstimateOthChOnItemRowHolder";
import { ListVouOthChargeResType } from "../../types/res/list/ListVouOthChargeResType";
import { VouOthChOnItemType } from "../../types/VouOthChOnItemType";
import AddEstimateOthChOnItemRowSv from "./AddEstimateOthChOnItemRowSv";

interface AddEstimateOthChOnItemSvProps {
	vouOthChOnItemResMap: Map<number, ListVouOthChargeResType>;
	vouOthChOnItemResArrForDd: DropDownItemType[];
	vouOthChOnItemArr: VouOthChOnItemType[];
	handleOthChOnItemChange: (vouOthChOnItemArr: VouOthChOnItemType[]) => void;
}

function AddEstimateOthChOnItemSv({
	vouOthChOnItemResMap,
	vouOthChOnItemResArrForDd,
	vouOthChOnItemArr,
	handleOthChOnItemChange
}: AddEstimateOthChOnItemSvProps): JSX.Element {

	const [estimateOthChOnItemRowHolderArr, setEstimateOthChOnItemRowHolderArr] = useState<EstimateOthChOnItemRowHolder[]>([]);

	useEffect(() => {

		for (let i = 0; i < estimateOthChOnItemRowHolderArr.length; i++) {
			let estimateOthChOnItemRowHolder = estimateOthChOnItemRowHolderArr[i];

			if (!isValid(estimateOthChOnItemRowHolder)) {
				return;
			}
		}

		let vouOthChOnItemArrLoc: VouOthChOnItemType[] = [];
		for (let i = 0; i < estimateOthChOnItemRowHolderArr.length; i++) {
			let estimateOthChOnItemRowHolder = estimateOthChOnItemRowHolderArr[i];

			vouOthChOnItemArrLoc.push({
				othChId: estimateOthChOnItemRowHolder.othChId!,
				rate: parseFloat(estimateOthChOnItemRowHolder.rateStr!),
				rateTypeId: estimateOthChOnItemRowHolder.rateTypeId!
			});
		}

		handleOthChOnItemChange(vouOthChOnItemArrLoc);

	}, [estimateOthChOnItemRowHolderArr]);

	const handleClickAdd = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();

		setEstimateOthChOnItemRowHolderArr([...estimateOthChOnItemRowHolderArr, {}])
	};

	const handleUpdateRow = (index: number, estimateOthChOnItemRowHolder: EstimateOthChOnItemRowHolder) => {

		let newEstimateOthChOnItemRowHolder = [...estimateOthChOnItemRowHolderArr];
		newEstimateOthChOnItemRowHolder[index] = estimateOthChOnItemRowHolder;
		setEstimateOthChOnItemRowHolderArr(newEstimateOthChOnItemRowHolder);

	};

	const handleClickRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
		event.preventDefault();

		let newEstimateOthChOnItemRowHolder = [...estimateOthChOnItemRowHolderArr];
		newEstimateOthChOnItemRowHolder.splice(index, 1);
		setEstimateOthChOnItemRowHolderArr(newEstimateOthChOnItemRowHolder);
	};

	const isValid = (estimateOthChOnItemRowHolderArr: EstimateOthChOnItemRowHolder): boolean => {

		if (
			estimateOthChOnItemRowHolderArr.othChId === undefined
			|| estimateOthChOnItemRowHolderArr.othChId === null
			|| estimateOthChOnItemRowHolderArr.othChId === -1
			|| vouOthChOnItemResMap?.get(estimateOthChOnItemRowHolderArr.othChId) === undefined
			|| vouOthChOnItemResMap?.get(estimateOthChOnItemRowHolderArr.othChId) === null
		) {
			return false;
		}

		if (estimateOthChOnItemRowHolderArr.rateStr === undefined
			|| estimateOthChOnItemRowHolderArr.rateStr === null
			|| !StringHelper2.isNum(estimateOthChOnItemRowHolderArr.rateStr)
		) {
			return false;
		}

		if (
			estimateOthChOnItemRowHolderArr.rateTypeId === undefined
			|| estimateOthChOnItemRowHolderArr.rateTypeId === null
			|| estimateOthChOnItemRowHolderArr.rateTypeId === -1
			|| RateTypeEnum.getById(estimateOthChOnItemRowHolderArr.rateTypeId) === undefined
			|| RateTypeEnum.getById(estimateOthChOnItemRowHolderArr.rateTypeId) === null
		) {
			return false;
		}

		return true;

	}

	return (
		<div className="d-flex g-3 justify-content-end">
			<div className="card">
				<div className="card-header">
					Other harge (After Tax)
				</div>
				<div className="card-body">

					{estimateOthChOnItemRowHolderArr.map((estimateOthChOnItemRowHolder, index) => (
						<div key={index}>
							<AddEstimateOthChOnItemRowSv
								vouOthChOnItemResMap={vouOthChOnItemResMap}
								vouOthChOnItemResArrForDd={vouOthChOnItemResArrForDd}
								vouOthChOnItem={index < vouOthChOnItemArr.length ? vouOthChOnItemArr[index] : null}
								estimateOthChOnItemRowHolder={estimateOthChOnItemRowHolder}
								handleClickRemove={handleClickRemove}
								handleUpdateRow={handleUpdateRow}
								myIndex={index}
								key={index}
							/>
						</div>
					))}

					<a
						onClick={(event) => handleClickAdd(event)}
						href="#"
						className="btn btn-primary">
						Add
					</a>
				</div>
			</div>
		</div>
	);

}

export default AddEstimateOthChOnItemSv;
