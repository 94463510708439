import RateTypeEnum from "../../enums/RateTypeEnum";
import RateTypeHelper from "../../helpers/RateTypeHelper";
import VouOthChHelper from "../../helpers/VouOthChHelper";
import DropDownItemType from "../../types/DropDownItemType";
import { EstimateOthChNonTaxableRowHolder } from "../../types/EstimateOthChNonTaxableRowHolder";
import { ListVouOthChargeResType } from "../../types/res/list/ListVouOthChargeResType";
import { VouOthChNonTaxableType } from "../../types/VouOthChNonTaxableType";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddEstimateOthChNonTaxableRowSvProps {
	vouOthChNonTaxableResMap: Map<number, ListVouOthChargeResType>;
	vouOthChNonTaxableResArrForDd: DropDownItemType[];
	vouOthChNonTaxable: VouOthChNonTaxableType | null;
	estimateOthChNonTaxableRowHolder: EstimateOthChNonTaxableRowHolder;
	handleClickRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void;
	handleUpdateRow: (key: number, estimateOthChNonTaxableRowHolder: EstimateOthChNonTaxableRowHolder) => void;
	myIndex: number;
}

function AddEstimateOthChNonTaxableRowSv({
	vouOthChNonTaxableResMap,
	vouOthChNonTaxableResArrForDd,
	vouOthChNonTaxable,
	estimateOthChNonTaxableRowHolder,
	handleClickRemove,
	handleUpdateRow,
	myIndex,
}: AddEstimateOthChNonTaxableRowSvProps): JSX.Element {

	return (
		<div className="row g-3 align-items-center">
			<div className="col-auto">
				<button
					onClick={(e) => handleClickRemove(e, myIndex)}
					type="submit"
					className="btn btn-primary mb-3"
				>
					Remove
				</button>
			</div>
			<div className="col-auto">
				<DownshiftSelect
					inputId={"vou_oth_ch_on_item"}
					value={
						estimateOthChNonTaxableRowHolder.othChId !== undefined &&
							estimateOthChNonTaxableRowHolder.othChId !== null &&
							vouOthChNonTaxableResMap.get(estimateOthChNonTaxableRowHolder.othChId) !== null &&
							vouOthChNonTaxableResMap.get(estimateOthChNonTaxableRowHolder.othChId) !== undefined
							?
							VouOthChHelper.getVouOthChForDd(vouOthChNonTaxableResMap.get(estimateOthChNonTaxableRowHolder.othChId)!)
							:
							null
					}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: e,
							rateStr: estimateOthChNonTaxableRowHolder.rateStr,
							rateTypeId: estimateOthChNonTaxableRowHolder.rateTypeId
						}
					)}
					options={vouOthChNonTaxableResArrForDd}
					isLoading={false}
					isDisabled={false}
					placeholder=""
				/>
			</div>
			<div className="col-auto">
				<input
					type="text"
					className="form-control"
					aria-describedby="passwordHelpInline"
					value={estimateOthChNonTaxableRowHolder.rateStr ?? ""}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: estimateOthChNonTaxableRowHolder.othChId,
							rateStr: e.target.value,
							rateTypeId: estimateOthChNonTaxableRowHolder.rateTypeId
						}
					)}
				/>
			</div>
			<div className="col-auto">
				<DownshiftSelect
					inputId={"rateType"}
					value={
						estimateOthChNonTaxableRowHolder.rateTypeId !== null &&
							estimateOthChNonTaxableRowHolder.rateTypeId !== undefined &&
							RateTypeEnum.getById(estimateOthChNonTaxableRowHolder.rateTypeId) !== null &&
							RateTypeEnum.getById(estimateOthChNonTaxableRowHolder.rateTypeId) !== undefined
							?
							RateTypeHelper.getRateTypeForDd(RateTypeEnum.getById(estimateOthChNonTaxableRowHolder.rateTypeId)!)
							:
							null
					}
					onChange={(e) => handleUpdateRow(
						myIndex,
						{
							othChId: estimateOthChNonTaxableRowHolder.othChId,
							rateStr: estimateOthChNonTaxableRowHolder.rateStr,
							rateTypeId: e
						}
					)}
					options={RateTypeHelper.getRateTypesForDd()}
					isLoading={false}
					isDisabled={false}
					placeholder=""
				/>
			</div>
			<div className="col-auto">
				<input
					type="text"
					className="form-control"
					value={vouOthChNonTaxable?.derivedAmt?.toString() ?? ""}
					readOnly={true}
				/>
			</div>
		</div>
	);
}

export default AddEstimateOthChNonTaxableRowSv;
