export class LstPurchaseReturnEnumCol {

	public static readonly NO = new LstPurchaseReturnEnumCol("no", "Number");
	public static readonly PURCHASE_RETURN_LEDGER = new LstPurchaseReturnEnumCol("purchase_return_ledger", "Purchase Return Ledger");
	public static readonly DATE = new LstPurchaseReturnEnumCol("date", "Date");
	public static readonly SUPPLIER_NAME = new LstPurchaseReturnEnumCol("supplier_name", "Supplier");
	public static readonly CITY = new LstPurchaseReturnEnumCol("city", "City");
	public static readonly STATE_NAME = new LstPurchaseReturnEnumCol("state_name", "State");
	public static readonly LR_NO = new LstPurchaseReturnEnumCol("lr_no", "LR No");
	public static readonly LR_DATE = new LstPurchaseReturnEnumCol("lr_date", "LR Date");
	public static readonly TRANSPORTER_NAME = new LstPurchaseReturnEnumCol("transporter_name", "Transport");
	public static readonly AMT_PAYABLE = new LstPurchaseReturnEnumCol("amt_payable", "Amount Payable");
	public static readonly AMT_CLRD = new LstPurchaseReturnEnumCol("amt_clrd", "Amount Cleared");
	public static readonly AMT_UNCLRD = new LstPurchaseReturnEnumCol("amt_unclrd", "Amount Due");
	public static readonly NOTE = new LstPurchaseReturnEnumCol("note", "Note");
	public static readonly CREATED_BY = new LstPurchaseReturnEnumCol("created_by", "Created By");
	public static readonly CREATED_AT = new LstPurchaseReturnEnumCol("created_at", "Created At");

	private constructor(public readonly key: string, public readonly name: string) {
		
	}

	static get values(): LstPurchaseReturnEnumCol[] {
		return [
			this.NO,
			this.PURCHASE_RETURN_LEDGER,
			this.DATE,
			this.SUPPLIER_NAME,
			this.CITY,
			this.STATE_NAME,
			this.LR_NO,
			this.LR_DATE,
			this.TRANSPORTER_NAME,
			this.AMT_PAYABLE,
			this.AMT_CLRD,
			this.AMT_UNCLRD,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstPurchaseReturnEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
