export class DateFormatEnum {

	public static readonly APP_DATE = new DateFormatEnum("DD-MM-YYYY");
	public static readonly APP_DATE_LOOSE = new DateFormatEnum("D-M-Y");
	public static readonly APP_DATE_LOOSE_SLASH = new DateFormatEnum("d/M/y");
	public static readonly APP_DATE_LOOSE_DOT = new DateFormatEnum("d.M.y");
	public static readonly APP_DATETIME = new DateFormatEnum("DD-MM-yyyy hh:mm:ss a");
	public static readonly DB_DATE = new DateFormatEnum("YYYY-MM-DD");
	public static readonly DB_DATETIME = new DateFormatEnum("yyyy-MM-DD HH:mm:ss");
	public static readonly US_SL = new DateFormatEnum("MM/dd/yy");
	public static readonly US_LOOSE = new DateFormatEnum("M/d/y");
	public static readonly US_DASH = new DateFormatEnum("M-d-y");
	public static readonly MSHO = new DateFormatEnum("dd MMM; yyyy")

	private constructor(
		public readonly format: string) {
	}

}
