import Downshift from "downshift";
import { useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Option, OptionsList } from "./presentational";

export default function DownshiftSelect({
	inputId,
	value,
	onChange,
	options,
	isLoading,
	isDisabled,
	placeholder
}) {
	const [items, setItems] = useState(options);
	const inputRef = useRef(null);

	const itemToString = i => (i ? i.name : "");

	const filterItems = (items, value) => {
		return items.filter(item =>
			item.name.toLowerCase().includes(value.toLowerCase())
		);
	}

	const handleStateChange_old = changes => {
		if (
			changes.hasOwnProperty("inputValue") &&
			!(changes.hasOwnProperty("isOpen") && !changes.isOpen)
			&& changes.inputValue
		) {
			setItems(filterItems(options, changes.inputValue));
		}
		if (changes.hasOwnProperty("isOpen") && !changes.isOpen) {
			setItems(options);
		}
	};

	const handleStateChange = (changes) => {
		if (changes.hasOwnProperty("inputValue")) {
			setItems(filterItems(options, changes.inputValue));
		}
		if (!changes.isOpen) {
			setItems(options);
		}
	};


	const handleChange = selectedItem => {
		onChange(selectedItem ? selectedItem.id : -1);
		// setItems(options);
	};

	const stateReducer_old = (state, changes) => {
		if (changes.hasOwnProperty("isOpen") && changes.isOpen) {
			return {
				...changes,
				highlightedIndex: items.indexOf(state.selectedItem)
			};
		}
		return changes;
	};

	const stateReducer = (state, changes) => {
		if (changes.type === Downshift.stateChangeTypes.clickButton) {
			// Always open the menu on button click
			return { ...changes, isOpen: true };
		}
		return changes;
	};


	return (
		<Downshift
			inputId={inputId}
			labelId={`${inputId}-label`}
			menuId={`${inputId}-menu`}
			// Control prop, tells downshift which item is selected
			//selectedItem={value && options.find(item => item.id === value)}
			selectedItem={value}
			// Tells downshift to how format the selected item for the input text
			itemToString={itemToString}
			onStateChange={handleStateChange}
			onChange={handleChange}
			stateReducer={stateReducer}
		>
			{({
				getInputProps,
				getToggleButtonProps,
				getItemProps,
				getMenuProps,
				isOpen,
				clearSelection,
				selectedItem,
				highlightedIndex,
				openMenu,
				getRootProps,
				inputValue
			}) => (
				<InputGroup {...getRootProps()}>
					<Form.Control
						{...getInputProps({
							placeholder: placeholder || "SELECT...",
							onClick: openMenu,
							disabled: isDisabled || isLoading
						})}
						ref={inputRef}
					/>
					{selectedItem ? (
						<Button
							variant="light"
							onClick={() => {
								clearSelection();
								inputRef.current.focus();
							}}
							disabled={isDisabled || isLoading}
						>
							<i className="bi bi-x"></i>
						</Button>
					) : null}
					<Button
						{...getToggleButtonProps({
							variant: "light",
							onClick: () => !isOpen && inputRef.current.focus(),
							disabled: isDisabled || isLoading
						})}
					>
						{isOpen ? (<i className="bi bi-chevron-up"></i>) : (<i className="bi bi-chevron-down"></i>)}
					</Button>
					{!isOpen ? null : (
						<OptionsList {...getMenuProps()}>
							{(
								items.map((item, index) => (
									<Option
										key={item.id}
										index={index}
										highlightedIndex={highlightedIndex}
										item={item}
										selectedItem={selectedItem}
										inputValue={inputValue}
										{...getItemProps({
											item,
											index
										})}
									>
										{itemToString(item)}
									</Option>
								))
							)}
						</OptionsList>
					)}
				</InputGroup>
			)}
		</Downshift>
	);
}
