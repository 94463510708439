import { BASE_URL, TOKEN } from "../config";
import { GetProdObjectTypeEnum } from "../enums/GetProdObjectTypeEnum";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { getQueryString } from "../helpers/UrlSearchParamHelper";
import { ListProdForEsimateResType } from "../types/res/list/ListProdForEsimateResType";
import { ListProductResType } from "../types/res/list/ListProductResType";

export default class ProductApi {

	static async getProducts() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name), requestOptions);
		const listProduct: ListProductResType[] = await res.json();
		return listProduct
	}

	static async getProdForEstimate() {

		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.PRODUCT.name)
			+ getQueryString({ returnObjectType: GetProdObjectTypeEnum.PROD_FOR_ESTIMATE.enumName.toUpperCase() }),
			requestOptions);
		const listProduct: ListProdForEsimateResType[] = await res.json();
		return listProduct

	}
}