import { RowType } from "../types/RowType";
import ListViewPagination from "./ListViewPagination";

interface ListViewTableProps {
	colNames: string[];
	rows: Array<RowType>;
	setSelectedId: (value: React.SetStateAction<number | null>) => void
}

function ListViewTable({
	colNames,
	rows,
	setSelectedId
}: ListViewTableProps) {
	return (
		<>
			<div className="flex-grow-1 mb-2 table-responsive text-nowrap">
				<table
					id="navigate"
					className="table table-hover table-bordered table-sm overflow-x-auto m-0 fixTableHead"
				>
					<thead className="bg-body-tertiary">
						<tr>
							<th scope="col">
								<input
									className="form-check-input"
									type="checkbox"
									id="chkCheckAll"
								/>
							</th>
							<th scope="col">Sr. No.</th>
							{colNames.map((colName, index) => (
								<th key={index} scope="col">
									{colName}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="">
						{rows.map((row, i) => (
							<tr key={i} onClick={(e) => setSelectedId(row.id)}>
								<td>
									<div>
										<input
											className="form-check-input"
											type="checkbox"
											value=""
										/>
									</div>
								</td>
								<td tabIndex={0}>
									<div>{i + 1}</div>
								</td>
								{row.data.map((rowItem, j) => (
									<td key={j} tabIndex={0}>
										<div>{rowItem}</div>
									</td>
								))}
							</tr>
						))}
					</tbody>
					<tfoot className="bg-body-tertiary">
						<tr>
							<th scope="row">Total</th>
							<th>{rows.length}</th>
							{[...Array(rows[0].data.length)].map((x, i) => (
								<th key={i}></th>
							))}
						</tr>
					</tfoot>
				</table>
			</div>
			<ListViewPagination colNames={colNames} />
		</>
	);
}

export default ListViewTable;
