import { TotalAmtContainerType } from "../../types/TotalAmtContainerType";

interface AddEstimateTotalSvProps {
	totalAmtContainer: TotalAmtContainerType | null;
}

function AddEstimateTotalSv({
	totalAmtContainer,
}: AddEstimateTotalSvProps): JSX.Element {


	return (
		<div className="d-flex g-3 justify-content-end">


			<div className="card">
				<div className="card-header">
					Total
				</div>
				<div className="card-body">
					<div className="row align-items-center">
						<div className="col-auto">
							<label htmlFor="staticEmail" className="col-form-label">Total Amount</label>
						</div>
						<div className="col-auto">
							<input
								value={totalAmtContainer !== undefined && totalAmtContainer !== null ? totalAmtContainer.totalAmt.toString() : ""}
								readOnly={true}
								type="text"
								className="form-control"
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-auto">
							<label htmlFor="staticEmail" className="col-form-label">Round Off</label>
						</div>
						<div className="col-auto">
							<input
								value={totalAmtContainer !== undefined && totalAmtContainer !== null ? totalAmtContainer.roundOff.toString() : ""}
								readOnly={true}
								type="text"
								className="form-control"
							/>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-auto">
							<label htmlFor="staticEmail" className="col-form-label">Amount Payable</label>
						</div>
						<div className="col-auto">
							<input
								value={totalAmtContainer !== undefined && totalAmtContainer !== null ? totalAmtContainer.amtPayable.toString() : ""}
								readOnly={true}
								type="text"
								className="form-control"
							/>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}

export default AddEstimateTotalSv;
