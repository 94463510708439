export const getQueryString = (obj: any) => {

	const params = new URLSearchParams();

	for (const key in obj) {
		if (obj.hasOwnProperty(key)
			&& obj[key] !== undefined
			&& obj[key] !== null) {
			params.append(key, obj[key].toString());
		}
	}

	return "?" + params.toString();
};