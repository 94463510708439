import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, Row } from "react-bootstrap";
import MessageDialog from "../../dialogs/MessageDialog";
import { PriceListTypeEnum } from "../../enums/PriceListTypeEnum";
import RateTypeEnum from "../../enums/RateTypeEnum";
import { YesNoEnum } from "../../enums/YesNoEnum";
import PriceListTypeHelper from "../../helpers/PriceListTypeHelper";
import ProductHelper from "../../helpers/ProductHelper";
import StringHelper2 from "../../helpers/StringHelper2";
import UnitHelper from "../../helpers/UnitHelper";
import YesNoHelper from "../../helpers/YesNoHelper";
import { EstimateItemType } from "../../types/EstimateItemType";
import DropDownItemType from "../../types/DropDownItemType";
import { ListProdForEsimateResType } from "../../types/res/list/ListProdForEsimateResType";
import { ListTaxCatResType } from "../../types/res/list/ListTaxCatResType";
import { ListTaxResType } from "../../types/res/list/ListTaxResType";
import { ListUnitResType } from "../../types/res/list/ListUnitResType";
import DownshiftSelect from "../DownshiftSelectV2";

interface AddEstimateItemFormSvProps {
	products: ListProdForEsimateResType[];
	productsMap: Map<number, ListProdForEsimateResType>;
	productsForDd: DropDownItemType[];
	units: ListUnitResType[];
	unitsMap: Map<number, ListUnitResType>;
	taxMap: Map<number, ListTaxResType>;
	taxCatMap: Map<number, ListTaxCatResType>;
	handleItemChange: (addEstimateItem: EstimateItemType) => void;
	id?: number
}

function AddEstimateItemFormSv({
	products,
	productsMap,
	productsForDd,
	units,
	unitsMap,
	taxMap,
	taxCatMap,
	handleItemChange,
	id
}: AddEstimateItemFormSvProps): JSX.Element {

	const [qtyCurrent, setQtyCurrent] = useState<number | null>(null);
	const [qtyPendingSales, setPendingSales] = useState<number | null>(null);
	const [qtyPendingEstimate, setPendingEstimate] = useState<number | null>(null);
	const [qtyFinal, setFinal] = useState<number | null>(null);

	const [productId, setProductId] = useState<number>(-1);
	const [qtyStr, setQtyStr] = useState<string>("");
	const [unitId, setUnitId] = useState<number>(-1);
	const [priceListTypeId, setPriceListTypeId] = useState<number>(-1);
	const [rateStr, setRateStr] = useState<string>("");
	const [discStr, setDiscStr] = useState<string>("");
	const [isPending, setIsPending] = useState<boolean>(false);
	const [desc, setDesc] = useState<string>("");
	//


	const [yesNosForDd, setYesNosForDd] = useState<DropDownItemType[]>([]);

	const [unitsForDd, setUnitsForDd] = useState<DropDownItemType[]>([]);

	const [priceListTypesForDd, setPriceListTypesForDd] = useState<DropDownItemType[]>([]);

	//
	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	useEffect(() => {
		async function process() {

			setPriceListTypesForDd(PriceListTypeHelper.getPriceListTypesForDd());
			setYesNosForDd(YesNoHelper.getYesNosForDd());

		}
		process();
	}, []);

	const handleProdChange = (prodIdLoc: number) => {

		let qtyCurrentLoc = null;
		let qtyPendingSalesLoc = null;
		let qtyPendingEstimateLoc = null;
		let qtyFinalLoc = null;
		let unitsForDdLoc: ListUnitResType[] = [];

		if (
			prodIdLoc > 0 &&
			products !== undefined &&
			products !== null &&
			productsMap !== undefined &&
			productsMap !== null &&
			products.length > 0) {

			let product = productsMap.get(prodIdLoc);

			qtyCurrentLoc = product?.qtyCurrent!;
			qtyPendingSalesLoc = product?.pendingQtySales!;
			qtyPendingEstimateLoc = product?.pendingQtyEstimate!;

			if (qtyCurrentLoc === undefined || qtyCurrentLoc === null) {
				qtyCurrentLoc = 0;
			}
			if (qtyPendingSalesLoc === undefined || qtyPendingSalesLoc === null) {
				qtyPendingSalesLoc = 0;
			}
			if (qtyPendingEstimateLoc === undefined || qtyPendingEstimateLoc === null) {
				qtyPendingEstimateLoc = 0;
			}

			qtyFinalLoc = qtyCurrentLoc - qtyPendingSalesLoc - qtyPendingEstimateLoc;


			if (product?.unitId !== undefined && product?.unitId !== null) {
				unitsForDdLoc.push(unitsMap?.get(product.unitId)!);
				unitsForDdLoc.push(unitsMap?.get(product.salesOrderBoxUnitId)!);
			}

		}

		setQtyCurrent(qtyCurrentLoc);
		setPendingSales(qtyPendingSalesLoc);
		setPendingEstimate(qtyPendingEstimateLoc);
		setFinal(qtyFinalLoc);

		setProductId(prodIdLoc);

		setUnitsForDd(UnitHelper.getUnitsForDd(unitsForDdLoc));
	};

	const _handleClickSave = async (event: React.FormEvent<HTMLFormElement>) => {

		event.preventDefault();

		if (!isValid()) {
			return;
		}

	}

	const fillInUi = (/*productResType: EstimateResType*/) => {

	};

	const handleClose = () => setShowMessageDialog(false);
	const handleShow = () => setShowMessageDialog(true);

	const clearBoxes = () => {
		setProductId(-1);
		setQtyStr("");
		setUnitId(-1);
		setPriceListTypeId(-1);
		setRateStr("");
		setDiscStr("");
		setIsPending(false);
		setDesc("");
	};

	const handleSave: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();

		if (!isValid()) {
			return;
		}

		let productIdLoc: number = productId;
		let qtyLoc: number = parseFloat(qtyStr);
		let unitIdLoc: number = unitId;
		let priceListTypeIdLoc: number = priceListTypeId;
		let rateLoc: number = parseFloat(rateStr);
		let discLoc: number = 0;
		let isPendingLoc: boolean = isPending;
		let descLoc: string = desc;

		if (discStr !== undefined
			&& discStr !== null
			&& StringHelper2.isNum(discStr)
		) {
			discLoc = parseFloat(discStr);
		}

		let addEstimateItemLoc: EstimateItemType = {
			productId: productIdLoc,
			desc: descLoc,
			orderQty: qtyLoc,
			orderUnitId: unitIdLoc,
			priceListTypeId: priceListTypeIdLoc,
			priceListRate: rateLoc,
			discRate: discLoc,
			discRateTypeId: RateTypeEnum.PERCENT.id,
			isPending: isPendingLoc,
		};

		handleItemChange(addEstimateItemLoc);

		clearBoxes();

	};

	const isValid = (): boolean => {

		if (
			productId === undefined
			|| productId === null
			|| productsMap?.get(productId) === undefined
			|| productsMap?.get(productId) === null
		) {
			setMessageDialogMessage("Select product");
			setShowMessageDialog(true);
			return false;
		}

		if (qtyStr === undefined
			|| qtyStr === null
			|| !StringHelper2.isNum(qtyStr)
		) {
			setMessageDialogMessage("Invalid quantity");
			setShowMessageDialog(true);
			return false;
		}

		if (
			unitId === undefined
			|| unitId === null
			|| unitsMap?.get(unitId) === undefined
			|| unitsMap?.get(unitId) === null
		) {
			setMessageDialogMessage("Select unit");
			setShowMessageDialog(true);
			return false;
		}

		if (
			priceListTypeId === undefined
			|| priceListTypeId === null
			|| PriceListTypeEnum.getById(priceListTypeId) === null
		) {
			setMessageDialogMessage("Select price list type");
			setShowMessageDialog(true);
			return false;
		}

		if (
			rateStr === undefined
			|| rateStr === null
			|| !StringHelper2.isNum(rateStr)) {
			setMessageDialogMessage("Invalid rate");
			setShowMessageDialog(true);
			return false;
		}

		return true;
	}

	return (
		<>
			<Form onSubmit={handleSave}>

				<div className="row g-3 align-items-center mb-3">
					<div className="col-auto">
						<label className="col-form-label">Quantity</label>
					</div>
					<div className="col-auto">
						<input type="text" className="form-control form-control-sm" readOnly={true}
							value={qtyCurrent ? qtyCurrent.toLocaleString('en-IN') : ""}
						/>
					</div>
					<div className="col-auto">
						<label className="col-form-label">-</label>
					</div>
					<div className="col-auto">
						<input type="text" className="form-control form-control-sm" readOnly={true}
							value={qtyPendingSales ? qtyPendingSales.toLocaleString('en-IN') : ""}
						/>
					</div>
					<div className="col-auto">
						<label className="col-form-label">-</label>
					</div>
					<div className="col-auto">
						<input type="text" className="form-control form-control-sm" readOnly={true}
							value={qtyPendingEstimate ? qtyPendingEstimate.toLocaleString('en-IN') : ""}
						/>
					</div>
					<div className="col-auto">
						<label className="col-form-label form-control-sm">=</label>
					</div>
					<div className="col-auto">
						<input type="text" className="form-control" readOnly={true}
							value={qtyFinal ? qtyFinal.toLocaleString('en-IN') : ""}
						/>
					</div>

				</div>

				<Row className="mb-3" xs="auto">
					<Form.Group as={Col}>
						<Form.Label>Product</Form.Label>
						<DownshiftSelect
							inputId={"product"}
							value={productId > 0 && productsMap?.get(productId) ? ProductHelper.getProductForDd(productsMap?.get(productId)!) : null}
							onChange={handleProdChange}
							options={productsForDd}
							isLoading={false}
							isDisabled={false}
							placeholder=""
						/>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>&nbsp;</Form.Label>
						<FormControl
							size="sm" as={Button}
						>...</FormControl>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Quantity</Form.Label>
						<FormControl
							size="sm"
							type="text"
							value={qtyStr}
							onChange={(e) => setQtyStr(e.target.value)}
						/>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Unit</Form.Label>
						<DownshiftSelect
							inputId={"unit"}
							value={unitId > 0 && unitsMap?.get(unitId) ? UnitHelper.getUnitForDd(unitsMap?.get(unitId)!) : null}
							onChange={setUnitId}
							options={unitsForDd}
							isLoading={false}
							isDisabled={false}
							placeholder=""
						/>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Price List Type</Form.Label>
						<DownshiftSelect
							inputId={"price_list_type"}
							value={priceListTypeId > 0 && PriceListTypeEnum.getById(priceListTypeId) !== null ? PriceListTypeHelper.getPriceListTypeForDd(PriceListTypeEnum.getById(priceListTypeId)!) : null}
							onChange={(e) => setPriceListTypeId(e)}
							options={priceListTypesForDd}
							isLoading={false}
							isDisabled={false}
							placeholder=""
						/>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Rate</Form.Label>
						<FormControl
							size="sm"
							type="text"
							value={rateStr}
							onChange={(e) => setRateStr(e.target.value)}
						/>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Discount</Form.Label>
						<FormControl
							size="sm"
							type="text"
							value={discStr}
							onChange={(e) => setDiscStr(e.target.value)}
						/>
					</Form.Group>


					<Form.Group as={Col}>
						<Form.Label>Is Pending?</Form.Label>
						<DownshiftSelect
							inputId={"is_pending"}
							value={isPending ? YesNoHelper.getYesNoForDd(YesNoEnum.getByVal(isPending)!) : null}
							onChange={(e) => setIsPending(YesNoEnum.getById(e)?.val!)}
							options={yesNosForDd}
							isLoading={false}
							isDisabled={false}
							placeholder=""
						/>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>&nbsp;</Form.Label>
						<FormControl
							size="sm"
							as={Button}>Save</FormControl>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>&nbsp;</Form.Label>
						<FormControl
							size="sm"
							as={Button}>Cancel</FormControl>
					</Form.Group>

					<Form.Group as={Col}>
						<Form.Label>Description</Form.Label>
						<FormControl
							size="sm"
							type="text"
							onChange={(e) => setDesc(e.target.value)}
						/>
					</Form.Group>
				</Row>
			</Form>

			<MessageDialog
				modalMessage={messageDialogMessage}
				showDialog={showMessageDialog}
				setShowDialog={setShowMessageDialog}
			/>

		</>
	);
}

export default AddEstimateItemFormSv;
