export class CommncParentTypeEnum {
	public static readonly LEAD = new CommncParentTypeEnum("Lead", 1);
	public static readonly ACCT = new CommncParentTypeEnum("Account", 2);

	private constructor(
		public readonly name: string,
		public readonly id: number) {
	}

	static get values(): CommncParentTypeEnum[] {
		return [
			this.LEAD,
			this.ACCT,
		];
	}

	static getById(id: number): CommncParentTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

	static getByName(name: string): CommncParentTypeEnum | null {

		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.name === name) {
				return element;
			}
		}

		return null;
	}

	static getStrVal(): string[] {

		let strings: string[] = [];
		this.values.forEach(element => {
			strings.push(element.name);
		});
		return strings;

	}

}
