export class OpeningBalanceTypeEnum {
	public static readonly RECIEVABLE = new OpeningBalanceTypeEnum(1, "Recievable / Dr / Paid");
	public static readonly PAYABLE = new OpeningBalanceTypeEnum(2, "Payable / Cr / Recieved");

	private constructor(
		public readonly id: number,
		public readonly name: string) {
	}

	static get values(): OpeningBalanceTypeEnum[] {
		return [
			this.RECIEVABLE,
			this.PAYABLE,
			// this.ITALY,
			// this.SPAIN
		];
	}

	static getById(id: number): OpeningBalanceTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

}
