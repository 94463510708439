import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ListVouOthChargeSmType from "../../types/sm/ListVouOthChargeSmType";


interface ListVouOthChageSdPropsType {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    listVouOthChargeSm: ListVouOthChargeSmType;
    processOnSuccess: (listVouOthChargeSm: ListVouOthChargeSmType) => void;
}

function ListVouOthChargeSd({
    showModal,
    setShowModal,
    listVouOthChargeSm,
    processOnSuccess
}: ListVouOthChageSdPropsType) {

    const [name, setName] = useState(listVouOthChargeSm.name);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleClickSearch();
    }

    const handleClickSearch = () => {
        if (!isValid) {
            return;
        }

        let listVouOthChargeSmNew = new ListVouOthChargeSmType();

        if (name !== undefined && name !== null && name !== "") {
            listVouOthChargeSmNew.name = name;
        }

        setShowModal(false);

        processOnSuccess(listVouOthChargeSmNew);
    }

    const handleClickClose = () => {
        setShowModal(false);
    };

    const isValid = (): boolean => {
        return true;
    }

    return (
        <Modal show={showModal} onHide={handleClickClose}
            size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail" onSubmit={handleSubmit}>
                        <Form.Label column sm="2">
                            Name
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" value={name === null ? "" : name} onChange={(e) => setName(e.target.value)} />
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClickClose}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={handleClickSearch}>
                    SAVE
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ListVouOthChargeSd;

