export class LstPaymentEnumCol {

	public static readonly NO = new LstPaymentEnumCol("no");
	public static readonly DATE = new LstPaymentEnumCol("date");
	public static readonly FROM_ACCT_NAME = new LstPaymentEnumCol("from_acct_name");
	public static readonly TO_ACCT_NAME = new LstPaymentEnumCol("to_acct_name");
	public static readonly NOTE = new LstPaymentEnumCol("note");
	public static readonly AMOUNT = new LstPaymentEnumCol("amount");
	public static readonly AMT_CLRD = new LstPaymentEnumCol("amt_clrd");
	public static readonly AMT_UNCLRD = new LstPaymentEnumCol("amt_unclrd");
	public static readonly CREATED_BY = new LstPaymentEnumCol("created_by");
	public static readonly CREATED_AT = new LstPaymentEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstPaymentEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.FROM_ACCT_NAME,
			this.TO_ACCT_NAME,
			this.NOTE,
			this.AMOUNT,
			this.AMT_CLRD,
			this.AMT_UNCLRD,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstPaymentEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
