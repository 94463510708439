import { MenuResType } from "../types/res/MenuResType";

interface MenuButtonWiPlusProps {
  index: number;
  menu: MenuResType;
  openListView: (menu: MenuResType) => void;
  openAddView: (menu: MenuResType) => void;
}

function MenuButtonWiPlus({
  index,
  menu,
  openListView,
  openAddView,
}: MenuButtonWiPlusProps): JSX.Element {
  return (
    <div className="col-md-4 mb-4 menu-btn-wrapper">
      <div className="menu-btn-container">
        <div
          className="btn-group d-flex"
          role="group"
          aria-label="Basic example"
        >
          <button
            className="btn btn-secondary menu-btn justify-content-between flex-grow-1"
            type="button"
            onClick={() => openListView(menu)}
          >
            <span className="d-flex">
              <span className="flex-grow-1">
                <span className="fw-bold">
                  {index + 1}.{" "}
                  {menu.displayName === null || menu.displayName === ""
                    ? menu.name
                    : menu.displayName}
                </span>
                <span className="d-block kbs">CS7</span>
              </span>
            </span>
          </button>
          <button
            className="btn btn-secondary menu-btn-plus"
            type="button"
            onClick={() => openAddView(menu)}
          >
            <i className="fa-solid fa-plus fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default MenuButtonWiPlus;
