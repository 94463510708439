import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { ListTaxResType } from "../types/res/list/ListTaxResType";

export default class TaxApi {
	static async getTaxs() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "appliion/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.TAX.name), requestOptions);
		const listTax: ListTaxResType[] = await res.json();
		return listTax
	}
}