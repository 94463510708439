export class LstVouOthChargeEnumCol {

	public static readonly SEQ_NO = new LstVouOthChargeEnumCol("seq_no");
	public static readonly NAME = new LstVouOthChargeEnumCol("name");
	public static readonly SALES_ACCT = new LstVouOthChargeEnumCol("sales_acct");
	public static readonly SALES_RET_ACCT = new LstVouOthChargeEnumCol("sales_ret_acct");
	public static readonly PURCHASE_ACCT = new LstVouOthChargeEnumCol("purchase_acct");
	public static readonly PURCHASE_RET_ACCT = new LstVouOthChargeEnumCol("purchase_ret_acct");
	public static readonly RATE = new LstVouOthChargeEnumCol("rate");
	public static readonly RATE_TYPE = new LstVouOthChargeEnumCol("rate_type");
	public static readonly CALC_ON = new LstVouOthChargeEnumCol("calc_on");
	public static readonly IS_TAXABLE = new LstVouOthChargeEnumCol("is_taxable");
	public static readonly TAX = new LstVouOthChargeEnumCol("tax");
	public static readonly AUTO_ADD_SALES = new LstVouOthChargeEnumCol("auto_add_sales");
	public static readonly AUTO_ADD_SALES_RET = new LstVouOthChargeEnumCol("auto_add_sales_ret");
	public static readonly AUTO_ADD_PURCHASE = new LstVouOthChargeEnumCol("auto_add_purchase");
	public static readonly AUTO_ADD_PURCHASE_RET = new LstVouOthChargeEnumCol("auto_add_purchase_ret");
	public static readonly CALC_TYPE = new LstVouOthChargeEnumCol("calc_type");
	public static readonly CREATED_BY = new LstVouOthChargeEnumCol("created_by");
	public static readonly CREATED_AT = new LstVouOthChargeEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstVouOthChargeEnumCol[] {
		return [
			this.SEQ_NO,
			this.NAME,
			this.SALES_ACCT,
			this.SALES_RET_ACCT,
			this.PURCHASE_ACCT,
			this.PURCHASE_RET_ACCT,
			this.RATE,
			this.RATE_TYPE,
			this.CALC_ON,
			this.IS_TAXABLE,
			this.TAX,
			this.AUTO_ADD_SALES,
			this.AUTO_ADD_SALES_RET,
			this.AUTO_ADD_PURCHASE,
			this.AUTO_ADD_PURCHASE_RET,
			this.CALC_TYPE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstVouOthChargeEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
