export class LstProdEnumCol {

	public static readonly NAME = new LstProdEnumCol("name");
	public static readonly ALIAS = new LstProdEnumCol("alias");
	public static readonly UNIT = new LstProdEnumCol("unit");
	public static readonly PURCHASE_PRICE = new LstProdEnumCol("purchase_price");
	public static readonly SALES_PRICE = new LstProdEnumCol("sales_price");
	public static readonly MRP = new LstProdEnumCol("mrp");
	public static readonly DISC = new LstProdEnumCol("disc");
	public static readonly CAT = new LstProdEnumCol("cat");
	public static readonly TAX_CAT = new LstProdEnumCol("tax_cat");
	public static readonly HSN = new LstProdEnumCol("hsn");
	public static readonly TAX = new LstProdEnumCol("tax");
	public static readonly MIN_QTY = new LstProdEnumCol("min_qty");
	public static readonly REORDER_QTY = new LstProdEnumCol("reorder_qty");
	public static readonly MIN_QTY_DAYS = new LstProdEnumCol("min_qty_days");
	public static readonly REORDER_QTY_DAYS = new LstProdEnumCol("reorder_qty_days");
	public static readonly NOTE = new LstProdEnumCol("note");
	public static readonly CREATED_BY = new LstProdEnumCol("created_by");
	public static readonly CREATED_AT = new LstProdEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstProdEnumCol[] {
		return [
			this.NAME,
			this.ALIAS,
			this.UNIT,
			this.PURCHASE_PRICE,
			this.SALES_PRICE,
			this.MRP,
			this.DISC,
			this.CAT,
			this.TAX_CAT,
			this.HSN,
			this.TAX,
			this.MIN_QTY,
			this.REORDER_QTY,
			this.MIN_QTY_DAYS,
			this.REORDER_QTY_DAYS,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstProdEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
