import { PriceListTypeEnum } from "../../enums/PriceListTypeEnum";
import { YesNoEnum } from "../../enums/YesNoEnum";
import UnitHelper from "../../helpers/UnitHelper";
import { EstimateItemType } from "../../types/EstimateItemType";
import { ListProdForEsimateResType } from "../../types/res/list/ListProdForEsimateResType";
import { ListTaxCatResType } from "../../types/res/list/ListTaxCatResType";
import { ListTaxResType } from "../../types/res/list/ListTaxResType";
import { ListUnitResType } from "../../types/res/list/ListUnitResType";

interface AddEstimateItemsTableProps {
	taxMap: Map<number, ListTaxResType>;
	taxCatMap: Map<number, ListTaxCatResType>;
	productsMap: Map<number, ListProdForEsimateResType>;
	unitsMap: Map<number, ListUnitResType>;
	addEstimateItems: Array<EstimateItemType>;
}

function AddEstimateItemsTable({
	taxMap,
	taxCatMap,
	productsMap,
	unitsMap,
	addEstimateItems,
}: AddEstimateItemsTableProps) {

	return (
		<>
			<div className="flex-grow-1 mb-2 table-responsive text-nowrap">
				<table
					id="navigate"
					className="table table-hover table-bordered table-sm overflow-x-auto m-0 fixTableHead"
				>
					<thead className="bg-body-tertiary">
						<tr>
							<th scope="col">
								<input
									className="form-check-input"
									type="checkbox"
									id="chkCheckAll"
								/>
							</th>
							<th scope="col">Sr. No.</th>
							<th scope="col">Product</th>
							<th scope="col">Desc</th>
							<th scope="col">Order Qty</th>
							<th scope="col">Order Unit</th>
							<th scope="col">Qty</th>
							<th scope="col">Unit</th>
							<th scope="col">Is Pending?</th>
							<th scope="col">Price List Type</th>
							<th scope="col">Rate</th>
							<th scope="col">Disc.</th>
							<th scope="col">Amt</th>
							<th scope="col">Tax</th>
							<th scope="col">Tax Amt</th>
							<th scope="col">Total</th>
						</tr>
					</thead>
					<tbody className="">
						{addEstimateItems.map((addEstimateItem, i) => (
							<tr key={i}>
								<td>
									<div>
										<input
											className="form-check-input"
											type="checkbox"
											value=""
										/>
									</div>
								</td>
								<td tabIndex={0}>
									<div>{i + 1}</div>
								</td>
								<td tabIndex={0}><div>{productsMap.get(addEstimateItem.productId)?.name}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.desc}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.orderQty.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{unitsMap.get(addEstimateItem.orderUnitId)?.name}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedQty!.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{unitsMap.get(productsMap.get(addEstimateItem.productId)?.unitId!)?.name}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.isPending ? "PENDING" : null}</div></td>
								<td tabIndex={0}><div>{PriceListTypeEnum.getById(addEstimateItem.priceListTypeId)?.name}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedRate}</div></td>
								<td tabIndex={0}><div>{
									addEstimateItem.discRate !== undefined
										&& addEstimateItem.discRate !== null ?
										addEstimateItem.discRate.toLocaleString('en-IN') + " %"
										:
										""
								}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedAmtAfterDisc?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedTaxId !== undefined && addEstimateItem.derivedTaxId !== null ? taxMap.get(addEstimateItem.derivedTaxId)?.name : ""}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedTaxAmt?.toLocaleString('en-IN')}</div></td>
								<td tabIndex={0}><div>{addEstimateItem.derivedAmt?.toLocaleString('en-IN')}</div></td>
							</tr>
						))}
					</tbody>
					<tfoot className="bg-body-tertiary">
						<tr>
							<th scope="row">Total</th>
							<th>{addEstimateItems.length}</th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
					</tfoot>
				</table>
			</div>
		</>
	);
}

export default AddEstimateItemsTable;
