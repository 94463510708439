export class LstLeadEnumCol {

	public static readonly NAME = new LstLeadEnumCol("name", "Name");
	public static readonly CONTACT_PERSON_NAME = new LstLeadEnumCol("contact_person_name", "Contact Person Name");
	public static readonly ADDRESS = new LstLeadEnumCol("address", "Address");
	public static readonly CITY = new LstLeadEnumCol("city", "City");
	public static readonly STATE = new LstLeadEnumCol("state", "State");
	public static readonly PIN = new LstLeadEnumCol("pin", "PIN");
	public static readonly EMAIL = new LstLeadEnumCol("email", "Email");
	public static readonly CONTACT_NO_1 = new LstLeadEnumCol("contact_no_1", "Contact No 1");
	public static readonly CONTACT_NO_2 = new LstLeadEnumCol("contact_no_2", "Contact No 2");
	public static readonly CONTACT_NO_3 = new LstLeadEnumCol("contact_no_3", "Contact No 3");
	public static readonly COMPANY_DETAILS = new LstLeadEnumCol("company_details", "Company Details");
	public static readonly NOTE = new LstLeadEnumCol("note", "Note");
	public static readonly LEAD_SRC_NAME = new LstLeadEnumCol("lead_src_name", "Source");
	public static readonly LEAD_STATUS_NAME = new LstLeadEnumCol("lead_status_name", "Status");
	public static readonly IS_COMMNC_ON = new LstLeadEnumCol("is_commnc_on", "Communication On/End");
	public static readonly LAST_REMARK = new LstLeadEnumCol("last_remark", "Last Remark");
	public static readonly LAST_REMARK_DATE = new LstLeadEnumCol("last_remark_date", "Last Remark Date");
	public static readonly CREATED_BY = new LstLeadEnumCol("created_by", "Created By");
	public static readonly CREATED_AT = new LstLeadEnumCol("created_at", "Created At");

	private constructor(public readonly key: string, public readonly name: string) {
		
	}

	static get values(): LstLeadEnumCol[] {
		return [
			this.NAME,
			this.CONTACT_PERSON_NAME,
			this.ADDRESS,
			this.CITY,
			this.STATE,
			this.PIN,
			this.EMAIL,
			this.CONTACT_NO_1,
			this.CONTACT_NO_2,
			this.CONTACT_NO_3,
			this.COMPANY_DETAILS,
			this.NOTE,
			this.LEAD_SRC_NAME,
			this.LEAD_STATUS_NAME,
			this.IS_COMMNC_ON,
			this.LAST_REMARK,
			this.LAST_REMARK_DATE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstLeadEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
