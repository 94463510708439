import { useEffect, useState } from "react";
import { BASE_URL, TOKEN } from "../../config";
import useConfirm from "../../dialogs/ConfirmDialog";
import { ResourceEnum } from "../../enums/ResourceEnum";
import strFormat from "../../helpers/StringHelper";
import { ListViewPropsType } from "../../types/ListViewPropsType";
import { RowType } from "../../types/RowType";
import { TransporterResType } from "../../types/TransporterResType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";

import { ColSettingResType } from "../../types/res/ColSettingResType";
import { ListMenuEnum } from "../../enums/ListMenuEnum";
import ListPurchaseSm from "../../types/sm/ListAcctTypeSmType";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import { LstPurchaseEnumCol } from "../../enums/col/LstPurchaseEnumCol";
import { ListPurchaseResType } from "../../types/res/list/ListPurchaseResType";
import dateToString from "../../helpers/DateHelper";
import { DateFormatEnum } from "../../enums/DateFormatEnum";
import MessageDialog from "../../dialogs/MessageDialog";

function ListPurchase({
	menu,
	openAddView,
}: ListViewPropsType): JSX.Element {

	const resourceName = ResourceEnum.PURCHASE.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [listPurchaseSm, setListPurchaseSm] = useState<ListPurchaseSm>(new ListPurchaseSm());

	const [showSd, setShowSd] = useState<boolean>(false);
	const [sdResolve, setSdResolve] = useState<any>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();



	useEffect(() => {
		async function process() {

			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const res = await fetch(strFormat("%s/%s/%s/%s",
				BASE_URL,
				ResourceEnum.MENU.name,
				ListMenuEnum.PURCHASE.id,
				ResourceEnum.COL_SETTING.name,
			), requestOptions);

			if (res.status !== 200) {
				setMessageDialogMessage("Server error");
				setShowMessageDialog(true);
			}
			else {
				const data: ColSettingResType[] = await res.json();
				setColSettings(data);

				let colNamesLocal: string[] = [];
				data.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);
				loadData(listPurchaseSm, data);
			}

		}
		process();
	}, []);

	const handleClickAdd = () => {
		openAddView(menu);
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		openAddView(menu, selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listPurchaseSm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listPurchaseSm: ListPurchaseSm,
		colSettingsLocal?: ColSettingResType[]
	) => {

		let colSettingsToUse: ColSettingResType[];

		if (colSettingsLocal === undefined) {
			colSettingsToUse = colSettings;
		}
		else {
			colSettingsToUse = colSettingsLocal;
		}

		setListPurchaseSm(listPurchaseSm);

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listPurchaseSm)
			, requestOptions);
		const results: ListPurchaseResType[] = await res.json();

		let dataToReturn: RowType[] = [];

		let sumAmtClrd = 0;
		let sumAmtPayable = 0;
		let sumAmtUnclrd = 0;
		let sumCgstA = 0;
		let sumSgstA = 0;
		let sumIgstA = 0;
		let sumRoundOff = 0;
		let sumAmtAfterDisc = 0;
		let sumAmtAfterTax = 0;
		let sumQty = 0;
		let sumTotalAmt = 0;

		results.forEach((result) => {
			let oneRowArray: string[] = [];

			colSettingsToUse.forEach(colSetting => {
				switch (LstPurchaseEnumCol.getByKey(colSetting.colKey)) {
					case LstPurchaseEnumCol.AMT_CLRD:
						oneRowArray.push(result.amtClrd.toLocaleString('en-IN'));
						sumAmtClrd = sumAmtClrd + result.amtClrd;
						break;
					case LstPurchaseEnumCol.AMT_PAYABLE:
						oneRowArray.push(result.amtPayable.toLocaleString('en-IN'));
						sumAmtPayable = sumAmtPayable + result.amtPayable;
						break;
					case LstPurchaseEnumCol.AMT_UNCLRD:
						oneRowArray.push(result.amtUnclrd.toLocaleString('en-IN'));
						sumAmtUnclrd = sumAmtUnclrd + result.amtUnclrd;
						break;
					case LstPurchaseEnumCol.CITY:
						oneRowArray.push(result.city);
						break;
					case LstPurchaseEnumCol.DATE:
						oneRowArray.push(dateToString(result.date, DateFormatEnum.APP_DATETIME));
						break;
					case LstPurchaseEnumCol.NO:
						oneRowArray.push(result.no.toString());
						break;
					case LstPurchaseEnumCol.NOTE:
						oneRowArray.push(result.note);
						break;
					case LstPurchaseEnumCol.ORDER_DATE:
						oneRowArray.push(result.orderDate == null ? "" : dateToString(result.orderDate, DateFormatEnum.APP_DATE));
						break;
					case LstPurchaseEnumCol.ORDER_NO:
						oneRowArray.push(result.orderNo);
						break;
					case LstPurchaseEnumCol.STATE_NAME:
						oneRowArray.push(result.stateName);
						break;
					case LstPurchaseEnumCol.SUPPLIER_NAME:
						oneRowArray.push(result.supplierName);
						break;
					case LstPurchaseEnumCol.DAYS_CNT:
						oneRowArray.push(result.daysCnt.toString());
						break;
					case LstPurchaseEnumCol.CREATED_BY:
						oneRowArray.push(result.createdByUserName);
						break;
					case LstPurchaseEnumCol.CREATED_AT:
						oneRowArray.push(dateToString(result.createdAt, DateFormatEnum.APP_DATETIME));
						break;
					case LstPurchaseEnumCol.CGST_A:
						oneRowArray.push(result.cgstA == null ? "" : result.cgstA.toLocaleString('en-IN'));
						sumCgstA = sumCgstA + result.cgstA;
						break;
					case LstPurchaseEnumCol.IGST_A:
						oneRowArray.push(result.igstA == null ? "" : result.igstA.toLocaleString('en-IN'));
						sumIgstA = sumIgstA + result.igstA;
						break;
					case LstPurchaseEnumCol.ROUND_OFF:
						oneRowArray.push(result.roundOff == null ? "" : result.roundOff.toLocaleString('en-IN'));
						sumRoundOff = sumRoundOff + result.roundOff;
						break;
					case LstPurchaseEnumCol.SGST_A:
						oneRowArray.push(result.sgstA == null ? "" : result.sgstA.toLocaleString('en-IN'));
						sumSgstA = sumSgstA + result.sgstA;
						break;
					case LstPurchaseEnumCol.SUM_AMT_AFTER_DISC:
						oneRowArray.push(result.sumAmtAfterDisc == null ? "" : result.sumAmtAfterDisc.toLocaleString('en-IN'));
						sumAmtAfterDisc = sumAmtAfterDisc + result.sumAmtAfterDisc;
						break;
					case LstPurchaseEnumCol.SUM_AMT_AFTER_TAX:
						oneRowArray.push(result.sumAmtAfterTax == null ? "" : result.sumAmtAfterTax.toLocaleString('en-IN'));
						sumAmtAfterTax = sumAmtAfterTax + result.sumAmtAfterTax;
						break;
					case LstPurchaseEnumCol.SUM_QTY:
						oneRowArray.push(result.sumQty == null ? "" : result.sumQty.toLocaleString('en-IN'));
						sumQty = sumQty + result.sumQty;
						break;
					case LstPurchaseEnumCol.TOTAL_AMT:
						oneRowArray.push(result.totalAmt == null ? "" : result.totalAmt.toLocaleString('en-IN'));
						sumTotalAmt = sumTotalAmt + result.totalAmt;
						break;
					case LstPurchaseEnumCol.PURCHASE_LEDGER:
						oneRowArray.push(result.purchaseLedger == null ? "" : result.purchaseLedger);
						break;
				}
			});

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				showAdd={true}
				showEdit={true}
				showDelete={true}
				showExport={true}
				showPrint={false}
				showOpen={false}
				showOptions={false}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>
			) : null}

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListPurchase;
