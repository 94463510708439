import { useState } from "react";
import Cookies from "universal-cookie";
import { BASE_URL, TOKEN } from "../config";
import strFormat from "../helpers/StringHelper";
import loginImage from "../login.jpg";

interface LoginProps {
	setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

function Login({ setLoggedIn }: LoginProps): JSX.Element {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ username: username, password: password }),
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, "login"), requestOptions);
		const data = await res.json();

		const cookies = new Cookies();
		cookies.set("token", data.access_token);
		TOKEN.val = data.access_token;

		setLoggedIn(true);
	};

	return (
		<div
			className="d-flex justify-content-center align-items-center min-vh-100"
			style={{
				backgroundImage:
					'url("https://images.pexels.com/photos/924824/pexels-photo-924824.jpeg?cs=srgb&dl=pexels-jakub-novacek-924824.jpg&fm=jpg&w=1920&h=1280")',
			}}
		>
			<div className="d-flex shadow-lg bg-body-tertiary rounded">
				<div className="flex-fill">
					<img src={loginImage} className="rounded" alt="background" />
				</div>
				<div className="flex-fill p-2">
					<form onSubmit={handleSubmit}>
						<div className="text-center my-5 bg-orange-subtle">
							<i className="fa-solid fa-circle-user fa-6x mt-10"></i>
						</div>
						<div className="input-group mb-3">
							<span className="input-group-text" id="basic-addon1">
								<i className="fa-solid fa-user"></i>
							</span>
							<input
								type="text"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								className="form-control form-control-sm"
								placeholder="username"
								aria-label="Username"
								aria-describedby="basic-addon1"
							/>
						</div>
						<div className="input-group mb-3">
							<span className="input-group-text" id="basic-addon1">
								<i className="fa-solid fa-key"></i>
							</span>
							<input
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								className="form-control form-control-sm"
								placeholder="password"
								aria-label="Password"
								aria-describedby="basic-addon1"
							/>
						</div>
						<div className="d-grid">
							<button type="submit" className="btn btn-primary btn-sm">
								<i className="fa-solid fa-right-to-bracket"></i> Enter
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default Login;
