import { useEffect, useState } from "react";
import { BASE_URL, TOKEN } from "../../config";
import useConfirm from "../../dialogs/ConfirmDialog";
import MessageDialog from "../../dialogs/MessageDialog";
import { CalcOnTypeEnum } from "../../enums/CalcOnTypeEnum";
import { CalcTypeEnum } from "../../enums/CalcTypeEnum";
import { LstVouOthChargeEnumCol } from "../../enums/col/LstVouOthChargeEnumCol";
import { DateFormatEnum } from "../../enums/DateFormatEnum";
import { ListMenuEnum } from "../../enums/ListMenuEnum";
import RateTypeEnum from "../../enums/RateTypeEnum";
import { ResourceEnum } from "../../enums/ResourceEnum";
import dateToString from "../../helpers/DateHelper";
import strFormat from "../../helpers/StringHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import { ListViewPropsType } from "../../types/ListViewPropsType";
import { ColSettingResType } from "../../types/res/ColSettingResType";
import { ListVouOthChargeResType } from "../../types/res/list/ListVouOthChargeResType";
import { RowType } from "../../types/RowType";
import ListVouOthChargeSm from "../../types/sm/ListVouOthChargeSmType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";
import ListVouOthChargeSd from "../sd/ListVouOthChargeSd";

function ListVoucherOtherCharge({
	menu,
	openAddView,
}: ListViewPropsType): JSX.Element {

	const resourceName = ResourceEnum.VOU_OTH_CHARGE.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setSelectedId] = useState<number | null>(null);

	const [listVouOthChargeSm, setListVouOthChargeSm] = useState<ListVouOthChargeSm>(new ListVouOthChargeSm());

	const [showSd, setShowSd] = useState<boolean>(false);
	const [sdResolve, setSdResolve] = useState<any>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();

	useEffect(() => {
		async function process() {

			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const res = await fetch(strFormat("%s/%s/%s/%s",
				BASE_URL,
				ResourceEnum.MENU.name,
				ListMenuEnum.VOU_OTH_CHARGE.id,
				ResourceEnum.COL_SETTING.name,
			), requestOptions);

			if (res.status !== 200) {
				setMessageDialogMessage("Server error");
				setShowMessageDialog(true);
			}
			else {
				const data: ColSettingResType[] = await res.json();
				setColSettings(data);

				let colNamesLocal: string[] = [];
				data.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);
				loadData(listVouOthChargeSm, data);
			}

		}
		process();
	}, []);

	const handleClickAdd = () => {
		openAddView(menu);
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		openAddView(menu, selectedId);
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listVouOthChargeSm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = async () => {
		setShowSd(true);
	}

	/*
	const handleClickFilterAsync = async () => {
		if (await showDialog(setShowSd)) {
			console.log(listVouOthChargeSm);
		}
	};
	*/

	const handleClickClearFilter = () => {
		listVouOthChargeSm.reset();
		setListVouOthChargeSm(listVouOthChargeSm);
		loadData(listVouOthChargeSm);
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listVouOthChargeSm: ListVouOthChargeSm,
		colSettingsLocal?: ColSettingResType[]
	) => {

		let colSettingsToUse: ColSettingResType[];

		if (colSettingsLocal === undefined) {
			colSettingsToUse = colSettings;
		}
		else {
			colSettingsToUse = colSettingsLocal;
		}

		setListVouOthChargeSm(listVouOthChargeSm);

		let requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ "" // getQueryString(listVouOthChargeSm)
			, requestOptions);
		const results: ListVouOthChargeResType[] = await res.json();

		let dataToReturn: RowType[] = [];
		results.forEach((result) => {
			let oneRowArray: string[] = [];

			colSettingsToUse.forEach(colSetting => {
				switch (LstVouOthChargeEnumCol.getByKey(colSetting.colKey)) {
					case LstVouOthChargeEnumCol.AUTO_ADD_PURCHASE:
						oneRowArray.push(result.isAddAutoP ? "YES" : "NO");
						break;
					case LstVouOthChargeEnumCol.AUTO_ADD_PURCHASE_RET:
						oneRowArray.push(result.isAddAutoPR ? "YES" : "NO");
						break;
					case LstVouOthChargeEnumCol.AUTO_ADD_SALES:
						oneRowArray.push(result.isAddAutoS ? "YES" : "NO");
						break;
					case LstVouOthChargeEnumCol.AUTO_ADD_SALES_RET:
						oneRowArray.push(result.isAddAutoSR ? "YES" : "NO");
						break;
					case LstVouOthChargeEnumCol.CALC_ON:
						oneRowArray.push(CalcOnTypeEnum.getById(result.calcOnTypeId)?.name!);
						break;
					case LstVouOthChargeEnumCol.CALC_TYPE:
						oneRowArray.push(CalcTypeEnum.getByValue(result.calcTypeVal)?.name!);
						break;
					case LstVouOthChargeEnumCol.CREATED_AT:
						oneRowArray.push(dateToString(result.createdAt, DateFormatEnum.APP_DATETIME));
						break;
					case LstVouOthChargeEnumCol.CREATED_BY:
						oneRowArray.push(result.createdByUserName);
						break;
					case LstVouOthChargeEnumCol.IS_TAXABLE:
						oneRowArray.push(result.isTaxable ? "YES" : "NO");
						break;
					case LstVouOthChargeEnumCol.NAME:
						oneRowArray.push(result.name);
						break;
					case LstVouOthChargeEnumCol.PURCHASE_ACCT:
						oneRowArray.push(result.purchaseAcctName);
						break;
					case LstVouOthChargeEnumCol.PURCHASE_RET_ACCT:
						oneRowArray.push(result.purchaseRetAcctName);
						break;
					case LstVouOthChargeEnumCol.RATE:
						oneRowArray.push(result.rate === null ? "" : result.rate.toString());
						break;
					case LstVouOthChargeEnumCol.RATE_TYPE:
						oneRowArray.push(result.rateTypeId === null ? "" : RateTypeEnum.getById(result.rateTypeId)?.name!);
						break;
					case LstVouOthChargeEnumCol.SALES_ACCT:
						oneRowArray.push(result.salesAcctName);
						break;
					case LstVouOthChargeEnumCol.SALES_RET_ACCT:
						oneRowArray.push(result.salesRetAcctName);
						break;
					case LstVouOthChargeEnumCol.SEQ_NO:
						oneRowArray.push(result.seqNo.toString());
						break;
					case LstVouOthChargeEnumCol.TAX:
						oneRowArray.push(result.taxName);
						break;
				}
			});

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				showAdd={true}
				showEdit={true}
				showDelete={true}
				showExport={true}
				showPrint={false}
				showOpen={false}
				showOptions={false}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setSelectedId}
				/>
			) : null}

			<ListVouOthChargeSd
				showModal={showSd}
				setShowModal={setShowSd}
				listVouOthChargeSm={listVouOthChargeSm}
				processOnSuccess={loadData}
			/>

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListVoucherOtherCharge;
