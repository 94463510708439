export class OrderTypeEnum {
	public static readonly PURCHASE = new OrderTypeEnum(1, "Purchase");
	public static readonly SALES = new OrderTypeEnum(2, "Sales");

	private constructor(
		public readonly id: number,
		public readonly name: string) {
	}

	static get values(): OrderTypeEnum[] {
		return [
			this.PURCHASE,
			this.SALES,
		];
	}

	static getByName(name: string): OrderTypeEnum | null {
		this.values.forEach(element => {
			if (element.name === name) {
				return name;
			}
		});
		return null;
	}

	static getById(id: number): OrderTypeEnum | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.id === id) {
				return element;
			}
		}
		return null;
	}

}
