import DropDownItemType from "../types/DropDownItemType";
import { ListVouOthChargeResType } from "../types/res/list/ListVouOthChargeResType";

export default class VouOthChHelper {

	static getVouOthChArrForDd(listVouOthCh: ListVouOthChargeResType[]) {

		let finalArr: DropDownItemType[] = [];
		listVouOthCh.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

	static getVouOthChForDd(listVouOthCh: ListVouOthChargeResType): DropDownItemType {
		return {
			id: listVouOthCh.id,
			name: listVouOthCh.name
		};
	}

}