import DropDownItemType from "../types/DropDownItemType";
import { ListEstimateStatusResType } from "../types/res/list/ListEstimateStatusResType";

export default class EstimateStatusHelper {

	static getEstimateStatusForDd(listEstimateStatus: ListEstimateStatusResType[]) {

		let finalArr: DropDownItemType[] = [];
		listEstimateStatus.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

}