import { TaxAmtContainerType } from "../../types/TaxAmtContainerType";

interface AddEstimateTaxSvProps {
	isAddTax: boolean;
	setIsAddTax: React.Dispatch<React.SetStateAction<boolean>>;
	isIntra: boolean;
	taxAmtContainer: TaxAmtContainerType | null;
}

function AddEstimateTaxSv({
	isAddTax,
	setIsAddTax,
	isIntra,
	taxAmtContainer
}: AddEstimateTaxSvProps): JSX.Element {

	return (
		<div className="d-flex g-3 justify-content-end">

			<div className="card">
				<div className="card-header">
					Tax
				</div>
				<div className="card-body">
					<div className="form-check">
						<input
							checked={isAddTax}
							onChange={(e) => setIsAddTax(e.target.checked)}
							className="form-check-input"
							type="checkbox"
							id="isAddTax" />
						<label className="form-check-label" htmlFor="flexCheckDefault">
							Add Tax
						</label>
					</div>
					{
						isAddTax ?
							<>
								{isIntra ?
									<>
										<div className="row align-items-center">
											<div className="col-auto">
												<label htmlFor="staticEmail" className="col-form-label">CGST</label>
											</div>
											<div className="col-auto">
												<input
													value={taxAmtContainer !== undefined && taxAmtContainer !== null ? taxAmtContainer.cgstA.toString() : ""}
													readOnly={true}
													type="text"
													className="form-control"
												/>
											</div>
										</div>
										<div className="row align-items-center">
											<div className="col-auto">
												<label htmlFor="staticEmail" className="col-form-label">SGST</label>
											</div>
											<div className="col-auto">
												<input
													value={taxAmtContainer !== undefined && taxAmtContainer !== null ? taxAmtContainer.sgstA.toString() : ""}
													readOnly={true}
													type="text"
													className="form-control"
												/>
											</div>
										</div>
									</>
									:
									<div className="row align-items-center">
										<div className="col-auto">
											<label htmlFor="staticEmail" className="col-form-label">IGST</label>
										</div>
										<div className="col-auto">
											<input
												value={taxAmtContainer !== undefined && taxAmtContainer !== null ? taxAmtContainer.igstA.toString() : ""}
												readOnly={true}
												type="text"
												className="form-control"
											/>
										</div>
									</div>
								}
							</>
							:
							""
					}
				</div>
			</div>
		</div>
	);
}

export default AddEstimateTaxSv;
