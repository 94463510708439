import { MenuResType } from "../types/res/MenuResType";
import MenuButton from "./MenuButton";

interface LoginProps {
  menus: MenuResType[];
  openMenuView: (parentMenu: MenuResType) => void;
  openListView: (menu: MenuResType) => void;
  openAddView: (menu: MenuResType) => void;
}

function Menu({
  menus,
  openMenuView,
  openListView,
  openAddView,
}: LoginProps): JSX.Element {
  return (
    <>
      <div id="menu-container" className="container">
        <h5 className="mb-3">Menu</h5>
        <div className="row">
          {menus.map((menu, index) => (
            <MenuButton
              key={menu.id}
              menu={menu}
              index={index}
              openMenuView={openMenuView}
              openListView={openListView}
              openAddView={openAddView}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Menu;
