export class LstBankEnumCol {

	public static readonly NAME = new LstBankEnumCol("name");
	public static readonly ACCT_TYPE_NAME = new LstBankEnumCol("acct_type_name");
	public static readonly OB_TYPE_ID = new LstBankEnumCol("ob_type_id");
	public static readonly OB = new LstBankEnumCol("ob");
	public static readonly ADDRESS = new LstBankEnumCol("address");
	public static readonly CONTACT_NO = new LstBankEnumCol("contact_no");
	public static readonly IFSC = new LstBankEnumCol("ifsc");
	public static readonly BRANCH = new LstBankEnumCol("branch");
	public static readonly NOTE = new LstBankEnumCol("note");
	public static readonly CREATED_BY = new LstBankEnumCol("created_by");
	public static readonly CREATED_AT = new LstBankEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstBankEnumCol[] {
		return [
			this.NAME,
			this.ACCT_TYPE_NAME,
			this.OB_TYPE_ID,
			this.OB,
			this.ADDRESS,
			this.CONTACT_NO,
			this.IFSC,
			this.BRANCH,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstBankEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
