import DropDownItemType from "../types/DropDownItemType";
import { ListTransporterResType } from "../types/res/list/ListTransporterResType";

export default class TransporterHelper {

	static getTransportersForDd(listTransporters: ListTransporterResType[]) {

		let finalArr: DropDownItemType[] = [];
		listTransporters.forEach(item => {
			finalArr.push({
				id: item.id,
				name: item.name
			});
		});
		return finalArr;

	}

}