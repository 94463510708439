import { useEffect, useState } from "react";
import {
	CURRENT_LAYOUT,
	CURRENT_TITLE,
	LAYOUTS2,
	MENU_STACK,
	TITLE_STACK
} from "../config";
import { MenuResType } from "../types/res/MenuResType";
import { getMenus } from "../Util";
import AddEstimate from "./add/AddEstimate";
import AddTransporter from "./add/AddTransporter";
import AddUnit from "./add/AddUnit";
import { Layout } from "./Layout";
import ListAcct from "./list/ListAcct";
import ListAcctType from "./list/ListAcctType";
import ListAgent from "./list/ListAgent";
import ListBank from "./list/ListBank";
import ListCashDiscVou from "./list/ListCashDiscVou";
import ListCdpJournalVou from "./list/ListCdpJournalVou";
import ListCommissionList from "./list/ListCommissionList";
import ListCommnc from "./list/ListCommnc";
import ListCompanyProfile from "./list/ListCompanyProfile";
import ListContra from "./list/ListContra";
import ListCustomer from "./list/ListCustomer";
import ListEstimate from "./list/ListEstimate";
import ListInvAdjust from "./list/ListInvAdjust";
import ListJournal from "./list/ListJournal";
import ListLead from "./list/ListLead";
import ListLeadSource from "./list/ListLeadSource";
import ListLeadStatus from "./list/ListLeadStatus";
import ListMsgTemplate from "./list/ListMsgTemplate";
import ListOrderAdjust from "./list/ListOrderAdjust";
import ListPayment from "./list/ListPayment";
import ListPi from "./list/ListPi";
import ListProduct from "./list/ListProduct";
import ListProductCategory from "./list/ListProductCategory";
import ListProductOthCharge from "./list/ListProductOthCharge";
import ListPurchase from "./list/ListPurchase";
import ListPurchaseOrder from "./list/ListPurchaseOrder";
import ListPurchasePriceList from "./list/ListPurchasePriceList";
import ListPurchaseReturn from "./list/ListPurchaseReturn";
import ListReceipt from "./list/ListReceipt";
import ListReminder from "./list/ListReminder";
import ListSales from "./list/ListSales";
import ListSalesOrder from "./list/ListSalesOrder";
import ListSalesPriceList from "./list/ListSalesPriceList";
import ListSalesReturn from "./list/ListSalesReturn";
import ListService from "./list/ListService";
import ListSupplier from "./list/ListSupplier";
import ListTax from "./list/ListTax";
import ListTaxCategory from "./list/ListTaxCategory";
import ListTransporter from "./list/ListTransporter";
import ListUnit from "./list/ListUnit";
import ListVoucherOtherCharge from "./list/ListVouOthCharge";
import Menu from "./Menu";
import Topbar from "./Topbar";

function Container() {

	const [loading, setLoading] = useState(true);
	// const [menu, setMenu] = useState(null);
	const [componentToRender, setComponentToRender] =
		useState<JSX.Element | null>(null);
	const [pageTitle, setPageTitle] = useState<string>("Menu");

	const openMenuView: (parentMenu: MenuResType) => void = (
		parentMenu: MenuResType
	) => {
		if (!(parentMenu.id === 0)) {
			MENU_STACK.push(CURRENT_LAYOUT.val);
			TITLE_STACK.push(CURRENT_TITLE.val);
		}

		CURRENT_LAYOUT.val = (
			<Menu
				menus={parentMenu.children}
				openMenuView={openMenuView}
				openListView={openListView}
				openAddView={openAddView}
			/>
		);
		CURRENT_TITLE.val =
			parentMenu.displayName === null
				? "Menu"
				: parentMenu.displayName === ""
					? parentMenu.name
					: parentMenu.displayName;

		setPageTitle(CURRENT_TITLE.val);
		setComponentToRender(CURRENT_LAYOUT.val);
	};

	const openListView = (menu: MenuResType) => {

		switch (menu.name) {
			case Layout.REMINDER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListReminder menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.REMINDER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.COMMNC.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListCommnc menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.COMMNC.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.LEAD.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListLead menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.LEAD.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.CDP_VOU.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListCdpJournalVou menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PAYMENT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.JOURNAL.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListJournal menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.JOURNAL.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.CONTRA.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListContra menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.CONTRA.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.RECEIPT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListReceipt menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.RECEIPT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PAYMENT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListPayment menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PAYMENT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SALES_RETURN.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListSalesReturn menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.SALES_RETURN.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SALES.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListSales menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.SALES.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PI.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListPi menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PI.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SALES_ORDER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListSalesOrder menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.SALES_ORDER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.ESTIMATE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListEstimate menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.ESTIMATE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PURCHASE_RETURN.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListPurchaseReturn menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PURCHASE_RETURN.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PURCHASE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListPurchase menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PURCHASE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PURCHASE_ORDER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListPurchaseOrder menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PURCHASE_ORDER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.COMM_VOU.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListCashDiscVou menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.COMM_VOU.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.CDP_VOU.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListCashDiscVou menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.CDP_VOU.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.ORDER_ADJUST.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListOrderAdjust menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.ORDER_ADJUST.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.BANK.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListBank menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.BANK.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.CUSTOMER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListCustomer menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.CUSTOMER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SUPPLIER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListSupplier menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.SUPPLIER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.AGENT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListAgent menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.AGENT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.ACCT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListAcct menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.ACCT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.ACCT_TYPE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListAcctType menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.ACCT_TYPE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.INV_ADJUST.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListInvAdjust menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.INV_ADJUST.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.COMM_LIST.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListCommissionList menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.COMM_LIST.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PURCHASE_PRICE_LIST.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListPurchasePriceList menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PURCHASE_PRICE_LIST.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SALES_PRICE_LIST.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListSalesPriceList menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.SALES_PRICE_LIST.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.SERVICE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListService menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.SERVICE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PROD.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListProduct menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PROD.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.PROD_OTH_CH.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListProductOthCharge menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.PROD_OTH_CH.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TAX_CAT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListTaxCategory menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.TAX_CAT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.CAT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListProductCategory menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.CAT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.UNIT.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListUnit menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.UNIT.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TRANSPORTER.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListTransporter menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.TRANSPORTER.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.TAX.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListTax menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.TAX.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.MSG_TEMPLATE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListMsgTemplate menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.MSG_TEMPLATE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.COMPANY_PROFILE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListCompanyProfile menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.COMPANY_PROFILE.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.VOU_OTH_CH.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListVoucherOtherCharge menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.VOU_OTH_CH.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.LEAD_SRC.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListLeadSource menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.LEAD_SRC.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case Layout.LEAD_STATUS.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <ListLeadStatus menu={menu} openAddView={openAddView} />;
				CURRENT_TITLE.val = Layout.LEAD_STATUS.displayName;

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}

		}
	};

	const openAddView = (menu: MenuResType, id?: number) => {

		switch (menu.name) {
			case Layout.ESTIMATE.key: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddEstimate id={id} />;
				CURRENT_TITLE.val = "Estimate";

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case LAYOUTS2.Transporter: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddTransporter id={id} />;
				CURRENT_TITLE.val = "Transporter";

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
			case LAYOUTS2.Unit: {
				MENU_STACK.push(CURRENT_LAYOUT.val);
				TITLE_STACK.push(CURRENT_TITLE.val);

				CURRENT_LAYOUT.val = <AddUnit />;
				CURRENT_TITLE.val = "Unit";

				setPageTitle(CURRENT_TITLE.val);
				setComponentToRender(CURRENT_LAYOUT.val);
				break;
			}
		}
	};

	const goBack = () => {
		if (MENU_STACK.length > 0) {
			CURRENT_LAYOUT.val = MENU_STACK.pop()!;
			CURRENT_TITLE.val = TITLE_STACK.pop()!;
			setPageTitle(CURRENT_TITLE.val);
			setComponentToRender(CURRENT_LAYOUT.val);
		}
	};

	useEffect(() => {
		async function process() {
			let menus = await getMenus();
			openMenuView(menus);
		}
		process();
	}, []);

	// function handleMenuClick(menu) {
	// 	setMenu(menu);
	// }

	// console.log("/* menu");
	// console.log(menu);
	// console.log("menu */");

	return (
		<div className="d-flex flex-column vh-100">
			<Topbar title={pageTitle} goBack={goBack} />
			{componentToRender}
		</div>
	);
}

export default Container;
