import { useEffect, useState } from "react";
import { BASE_URL, TOKEN } from "../../config";
import useConfirm from "../../dialogs/ConfirmDialog";
import { ResourceEnum } from "../../enums/ResourceEnum";
import strFormat from "../../helpers/StringHelper";
import { ListViewPropsType } from "../../types/ListViewPropsType";
import { RowType } from "../../types/RowType";
import { TransporterResType } from "../../types/TransporterResType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";

import { ColSettingResType } from "../../types/res/ColSettingResType";
import { ListMenuEnum } from "../../enums/ListMenuEnum";
import ListCashDiscVouSm from "../../types/sm/ListCashDiscVouSmType";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import { LstCashDiscVouEnumCol } from "../../enums/col/LstCashDiscVouEnumCol";
import { ListCashDiscVouResType } from "../../types/res/list/ListCashDiscVouResType";
import dateToString from "../../helpers/DateHelper";
import { DateFormatEnum } from "../../enums/DateFormatEnum";
import MessageDialog from "../../dialogs/MessageDialog";

function ListCashDiscVou({
	menu,
	openAddView,
}: ListViewPropsType): JSX.Element {

	const resourceName = ResourceEnum.CASH_DISC_VOU.name;

	const [colSettings, setColSettings] = useState<ColSettingResType[]>([]);
	const [colNames, setColNames] = useState<string[]>([]);

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setselectedId] = useState<number | null>(null);

	const [listCashDiscVouSm, setListCashDiscVouSm] = useState<ListCashDiscVouSm>(new ListCashDiscVouSm());

	const [showSd, setShowSd] = useState<boolean>(false);
	const [sdResolve, setSdResolve] = useState<any>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();



	useEffect(() => {
		async function process() {

			const requestOptions = {
				method: "GET",
				headers: {
					"Authorization": "Bearer " + TOKEN.val,
					"Content-Type": "application/json"
				}
			}

			const res = await fetch(strFormat("%s/%s/%s/%s",
				BASE_URL,
				ResourceEnum.MENU.name,
				ListMenuEnum.CASH_DISC_VOU.id,
				ResourceEnum.COL_SETTING.name,
			), requestOptions);

			if (res.status !== 200) {
				setMessageDialogMessage("Server error");
				setShowMessageDialog(true);
			}
			else {
				const data: ColSettingResType[] = await res.json();
				setColSettings(data);

				let colNamesLocal: string[] = [];
				data.forEach((item) => {
					colNamesLocal.push(item.colName);
				});
				setColNames(colNamesLocal);
				loadData(listCashDiscVouSm, data);
			}

		}
		process();
	}, []);

	const handleClickAdd = () => {
		openAddView(menu);
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listCashDiscVouSm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listCashDiscVouSm: ListCashDiscVouSm,
		colSettingsLocal?: ColSettingResType[]
	) => {

		let colSettingsToUse: ColSettingResType[];

		if (colSettingsLocal === undefined) {
			colSettingsToUse = colSettings;
		}
		else {
			colSettingsToUse = colSettingsLocal;
		}

		setListCashDiscVouSm(listCashDiscVouSm);

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listCashDiscVouSm)
			, requestOptions);
		const results: ListCashDiscVouResType[] = await res.json();

		let dataToReturn: RowType[] = [];
		results.forEach((result) => {
			let oneRowArray: string[] = [];

			colSettingsToUse.forEach(colSetting => {
				switch (LstCashDiscVouEnumCol.getByKey(colSetting.colKey)) {
					case LstCashDiscVouEnumCol.AMT_DISC:
						oneRowArray.push(result.amtDisc.toLocaleString("en-IN", { minimumFractionDigits: 2 }));
						break;
					case LstCashDiscVouEnumCol.AMT_ITEM_VAL:
						oneRowArray.push(result.amtItemVal.toLocaleString("en-IN", { minimumFractionDigits: 2 }));
						break;
					case LstCashDiscVouEnumCol.AMT_RCVD:
						oneRowArray.push(result.amtRcvd.toLocaleString("en-IN", { minimumFractionDigits: 2 }));
						break;
					case LstCashDiscVouEnumCol.AMT_SALES:
						oneRowArray.push(result.amtSales.toLocaleString("en-IN", { minimumFractionDigits: 2 }));
						break;
					case LstCashDiscVouEnumCol.CUSTOMER_NAME:
						oneRowArray.push(result.customerName);
						break;
					case LstCashDiscVouEnumCol.DATE:
						oneRowArray.push(dateToString(result.date, DateFormatEnum.APP_DATETIME));
						break;
					case LstCashDiscVouEnumCol.DAYS:
						oneRowArray.push(result.days.toString());
						break;
					case LstCashDiscVouEnumCol.DISC_GIVEN:
						oneRowArray.push(result.discGiven.toLocaleString("en-IN", { minimumFractionDigits: 2 }));
						break;
					case LstCashDiscVouEnumCol.DISC_PENDING:
						oneRowArray.push(result.discPending.toLocaleString("en-IN", { minimumFractionDigits: 2 }));
						break;
					case LstCashDiscVouEnumCol.NO:
						oneRowArray.push(result.no.toString());
						break;
					case LstCashDiscVouEnumCol.RATE:
						oneRowArray.push(result.rate.toLocaleString("en-IN", { minimumFractionDigits: 2 }) + "%");
						break;
					case LstCashDiscVouEnumCol.RECEIPT_DATE:
						oneRowArray.push(result.receiptDate === null ? "" : dateToString(result.receiptDate, DateFormatEnum.APP_DATE));
						break;
					case LstCashDiscVouEnumCol.RECEIPT_NO:
						oneRowArray.push(result.receiptNo);
						break;
					case LstCashDiscVouEnumCol.SALES_DATE:
						oneRowArray.push(dateToString(result.salesDate, DateFormatEnum.APP_DATE));
						break;
					case LstCashDiscVouEnumCol.SALES_NO:
						oneRowArray.push(result.salesNo);
						break;
					case LstCashDiscVouEnumCol.CREATED_BY:
						oneRowArray.push(result.createdByUserName);
						break;
					case LstCashDiscVouEnumCol.CREATED_AT:
						oneRowArray.push(dateToString(result.createdAt, DateFormatEnum.APP_DATETIME));
						break;
				}
			});

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				showAdd={true}
				showEdit={true}
				showDelete={true}
				showExport={true}
				showPrint={false}
				showOpen={false}
				showOptions={false}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setselectedId}
				/>
			) : null}

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListCashDiscVou;
