export default class ListCashDiscVouSm {

	no: string | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;

	salesNo: string | undefined;
	salesDateFrom: Date | undefined;
	salesDateTo: Date | undefined;

	customerId: number | undefined;
	customerName: string | undefined;

	receiptNo: string | undefined;
	receiptDateFrom: Date | undefined;
	receiptDateTo: Date | undefined;

	reset(): void {

		this.no = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;

		this.salesNo = undefined;
		this.salesDateFrom = undefined;
		this.salesDateTo = undefined;

		this.customerId = undefined;
		this.customerName = undefined;

		this.receiptNo = undefined;
		this.receiptDateFrom = undefined;
		this.receiptDateTo = undefined;

	}

	isBlank(): boolean {
		return this.no === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined

			&& this.salesNo === undefined
			&& this.salesDateFrom === undefined
			&& this.salesDateTo === undefined

			&& this.customerId === undefined
			&& this.customerName === undefined

			&& this.receiptNo === undefined
			&& this.receiptDateFrom === undefined
			&& this.receiptDateTo === undefined;
	}
}
