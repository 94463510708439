import { useEffect, useState } from "react";
import { BASE_URL, TOKEN } from "../../config";
import useConfirm from "../../dialogs/ConfirmDialog";
import MessageDialog from "../../dialogs/MessageDialog";
import { ResourceEnum } from "../../enums/ResourceEnum";
import strFormat from "../../helpers/StringHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import { ListViewPropsType } from "../../types/ListViewPropsType";
import { ColSettingResType } from "../../types/res/ColSettingResType";
import { ListProductCategoryResType } from "../../types/res/list/ListProductCategoryResType";
import { RowType } from "../../types/RowType";
import ListProductCategorySm from "../../types/sm/ListProductCategorySmType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";


function ListProductCategory({
	menu,
	openAddView,
}: ListViewPropsType): JSX.Element {

	const resourceName = ResourceEnum.PRODUCT_CAT.name;

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setselectedId] = useState<number | null>(null);

	const [listProductCategorySm, setListProductCategorySm] = useState<ListProductCategorySm>(new ListProductCategorySm());

	const [showSd, setShowSd] = useState<boolean>(false);
	const [sdResolve, setSdResolve] = useState<any>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();

	const colNames = [
		"Name",
		"Note",
		"Parent Category",
		"Created By",
		"Created At",
	];

	useEffect(() => {
		async function process() {
			loadData(listProductCategorySm);
		}
		process();
	}, []);

	const handleClickAdd = () => {
		openAddView(menu);
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listProductCategorySm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listProductCategorySm: ListProductCategorySm,
		colSettingsLocal?: ColSettingResType[]
	) => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listProductCategorySm)
			, requestOptions);
		const results: ListProductCategoryResType[] = await res.json();

		let dataToReturn: RowType[] = [];
		results.forEach((result) => {
			let oneRowArray: string[] = [];

			oneRowArray.push(result.name);
			oneRowArray.push(result.note);
			let parentCategoryName: string = "";
			if (result.parentName != null) {
				parentCategoryName = result.parentName;
			}
			oneRowArray.push(parentCategoryName);
			oneRowArray.push(result.createdByUserName);
			oneRowArray.push(result.createdAt.toString());

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				showAdd={true}
				showEdit={true}
				showDelete={true}
				showExport={true}
				showPrint={false}
				showOpen={false}
				showOptions={false}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setselectedId}
				/>
			) : null}

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListProductCategory;
