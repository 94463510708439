export class LstPurchaseOrderEnumCol {

	public static readonly NO = new LstPurchaseOrderEnumCol("no");
	public static readonly DATE = new LstPurchaseOrderEnumCol("date");
	public static readonly SUPPLIER = new LstPurchaseOrderEnumCol("supplier");
	public static readonly CITY = new LstPurchaseOrderEnumCol("city");
	public static readonly STATE = new LstPurchaseOrderEnumCol("state");
	public static readonly QTY = new LstPurchaseOrderEnumCol("qty");
	public static readonly NOTE = new LstPurchaseOrderEnumCol("note");
	public static readonly CREATED_BY = new LstPurchaseOrderEnumCol("created_by");
	public static readonly CREATED_AT = new LstPurchaseOrderEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstPurchaseOrderEnumCol[] {
		return [
			this.NO,
			this.DATE,
			this.SUPPLIER,
			this.CITY,
			this.STATE,
			this.QTY,
			this.NOTE,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstPurchaseOrderEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
