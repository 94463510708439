export default class ListSalesReturnSm {

	salesReturnLedgerId: number | undefined;
	no: number | undefined;
	dateFrom: Date | undefined;
	dateTo: Date | undefined;
	customerId: number | undefined;
	customerName: string | undefined;
	city: string | undefined;
	stateId: number | undefined;
	state: string | undefined;
	agentId: number | undefined;
	agentName: string | undefined;
	note: string | undefined;

	reset(): void {
		this.salesReturnLedgerId = undefined;
		this.no = undefined;
		this.dateFrom = undefined;
		this.dateTo = undefined;
		this.customerId = undefined;
		this.customerName = undefined;
		this.city = undefined;
		this.stateId = undefined;
		this.state = undefined;
		this.agentId = undefined;
		this.agentName = undefined;
		this.note = undefined;
	}

	isBlank(): boolean {
		return this.salesReturnLedgerId === undefined
			&& this.no === undefined
			&& this.dateFrom === undefined
			&& this.dateTo === undefined
			&& this.customerId === undefined
			&& this.customerName === undefined
			&& this.city === undefined
			&& this.stateId === undefined
			&& this.state === undefined
			&& this.agentId === undefined
			&& this.agentName === undefined
			&& this.note === undefined;
	}
}
