export class LstSupplierEnumCol {

	public static readonly NAME = new LstSupplierEnumCol("name");
	public static readonly ACCT_TYPE_NAME = new LstSupplierEnumCol("acct_type_name");
	public static readonly OB_TYPE_ID = new LstSupplierEnumCol("ob_type_id");
	public static readonly OB = new LstSupplierEnumCol("ob");
	public static readonly NAME_TO_PRINT = new LstSupplierEnumCol("name_to_print");
	public static readonly ADDRESS = new LstSupplierEnumCol("address");
	public static readonly CITY = new LstSupplierEnumCol("city");
	public static readonly STATE_NAME = new LstSupplierEnumCol("state_name");
	public static readonly PIN = new LstSupplierEnumCol("pin");
	public static readonly EMAIL = new LstSupplierEnumCol("email");
	public static readonly GST_NO = new LstSupplierEnumCol("gst_no");
	public static readonly BANK_NAME = new LstSupplierEnumCol("bank_name");
	public static readonly BANK_BRANCH_NAME = new LstSupplierEnumCol("bank_branch_name");
	public static readonly BANK_ACCT_NAME = new LstSupplierEnumCol("bank_acct_name");
	public static readonly BANK_ACCT_NO = new LstSupplierEnumCol("bank_acct_no");
	public static readonly BANK_IFSC = new LstSupplierEnumCol("bank_ifsc");
	public static readonly CREDIT_DAYS = new LstSupplierEnumCol("credit_days");
	public static readonly CREATED_BY = new LstSupplierEnumCol("created_by");
	public static readonly CREATED_AT = new LstSupplierEnumCol("created_at");

	private constructor(
		public readonly key: string) {
	}

	static get values(): LstSupplierEnumCol[] {
		return [
			this.NAME,
			this.ACCT_TYPE_NAME,
			this.OB_TYPE_ID,
			this.OB,
			this.NAME_TO_PRINT,
			this.ADDRESS,
			this.CITY,
			this.STATE_NAME,
			this.PIN,
			this.EMAIL,
			this.GST_NO,
			this.BANK_NAME,
			this.BANK_BRANCH_NAME,
			this.BANK_ACCT_NAME,
			this.BANK_ACCT_NO,
			this.BANK_IFSC,
			this.CREDIT_DAYS,
			this.CREATED_BY,
			this.CREATED_AT,
		];
	}

	static getByKey(key: string): LstSupplierEnumCol | null {
		for (let index = 0; index < this.values.length; index++) {
			const element = this.values[index];
			if (element.key === key) {
				return element;
			}
		}
		return null;
	}
}
