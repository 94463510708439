import { BASE_URL, TOKEN } from "../config";
import { ResourceEnum } from "../enums/ResourceEnum";
import strFormat from "../helpers/StringHelper";
import { ListTaxCatResType } from "../types/res/list/ListTaxCatResType";

export default class TaxCatApi {
	static async getTaxCats() {
		const requestOptions = {
			method: "GET",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}
		const res = await fetch(strFormat("%s/%s", BASE_URL, ResourceEnum.TAX_CAT.name), requestOptions);
		const listTaxCat: ListTaxCatResType[] = await res.json();
		return listTaxCat
	}
}