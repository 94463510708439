import React, { forwardRef } from "react";
import styles from "./styles.module.css";

export function InputWrapper({ children }) {
	return (
		<div className={`form-group ${styles["input-container"]}`}>{children}</div>
	);
}

export function Label({ fieldName, children }) {
	return (
		<label id={`${fieldName}-label`} htmlFor={fieldName}>
			{children}
		</label>
	);
}

export const Container = forwardRef((props, ref) => {
	return <div className={styles["downshift-container"]} {...props} ref={ref} />;
});

export const Input = forwardRef((props, ref) => {
	return (
		<input className={`form-control ${styles["input"]}`} {...props} ref={ref} />
	);
});

export function InputControls({ children }) {
	return <span className={styles["input-controls"]}>{children}</span>;
}

export function CloseButton(props) {
	return (
		<button {...props} aria-label="clear selection">
			<XIcon />
		</button>
	);
}

export function ControlsDivider() {
	return (
		<span className={styles["divider-container"]}>
			<span className={styles["divider"]} />
		</span>
	);
}

export const OptionsList = forwardRef(({ isVirtualized, ...rest }, ref) => {
	const ComponentType = isVirtualized ? "div" : "ul";
	const classNames = [styles["options-list"]];
	if (!isVirtualized) classNames.push(styles["max-height-200"]);

	return <ComponentType className={classNames.join(" ")} {...rest} ref={ref} />;
});

function BoldMatchText({ children = "", inputValue }) {
	const lowerText = children.toLowerCase();
	const matchIndex = lowerText.indexOf(inputValue.toLowerCase());

	if (matchIndex < 0) return children;

	const beginning = children.substr(0, matchIndex);
	const match = children.substr(matchIndex, inputValue.length);
	const end = children.substr(matchIndex + match.length);
	return (
		<>
			{beginning}
			<span className={styles["bold-option-text"]}>{match}</span>
			{end}
		</>
	);
}

export const Option = forwardRef(
	(
		{
			children,
			index,
			highlightedIndex,
			item,
			selectedItem,
			inputValue,
			isVirtualized,
			itemWidth,
			style,
			...rest
		},
		ref
	) => {
		const isActive = highlightedIndex === index;
		const isSelected = selectedItem === item;
		const classNames = [styles["option"]];

		if (isSelected) classNames.push(styles["is-selected"]);
		if (isActive) classNames.push(styles["is-active"]);
		if (isVirtualized) classNames.push(styles["is-virtualized"]);
		const newStyle = {
			...style,
			width: itemWidth || styles.width
		};

		return (
			<li className={classNames.join(" ")} {...rest} style={newStyle} ref={ref}>
				<BoldMatchText inputValue={inputValue}>{children}</BoldMatchText>
			</li>
		);
	}
);

export function NoOptionsMessage({ isVirtualized }) {
	const ComponentType = isVirtualized ? "div" : "li";
	return <ComponentType className={styles["option"]}>No options</ComponentType>;
}

export const ToggleButton = forwardRef(({ isOpen, ...props }, ref) => {
	return (
		<button {...props} ref={ref}>
			<ArrowIcon isOpen={isOpen} />
		</button>
	);
});

function ArrowIcon({ isOpen }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="currentColor"
			className="bi bi-caret-down-fill"
			viewBox="0 0 16 16"
			transform={isOpen ? "rotate(180)" : undefined}>
			<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
		</svg>
	);
}

function XIcon() {
	return (
		<svg
			viewBox="0 0 20 20"
			preserveAspectRatio="none"
			width={12}
			fill="transparent"
			stroke="#979797"
			strokeWidth="1.1px"
		>
			<path d="M1,1 L19,19" />
			<path d="M19,1 L1,19" />
		</svg>
	);
}

export function LoadingDots() {
	return (
		<div className={styles["loading-dots-container"]}>
			<div className={styles["loading-dots"]}>
				<div className={styles["loading-dot"]} />
				<div className={styles["loading-dot"]} />
				<div className={styles["loading-dot"]} />
			</div>
		</div>
	);
}
