import { useEffect, useState } from "react";
import { BASE_URL, TOKEN } from "../../config";
import useConfirm from "../../dialogs/ConfirmDialog";
import MessageDialog from "../../dialogs/MessageDialog";
import { ResourceEnum } from "../../enums/ResourceEnum";
import strFormat from "../../helpers/StringHelper";
import { getQueryString } from "../../helpers/UrlSearchParamHelper";
import { ListViewPropsType } from "../../types/ListViewPropsType";
import { ColSettingResType } from "../../types/res/ColSettingResType";
import { ListPurchasePriceListResType } from "../../types/res/list/ListPurchasePriceListResType";
import { RowType } from "../../types/RowType";
import ListPurchasePriceListSm from "../../types/sm/ListPurchasePriceListSmType";
import ListViewButtonBar from "../ListViewButtonBar";
import ListViewTable from "../ListViewTable";


function ListPurchasePriceList({
	menu,
	openAddView,
}: ListViewPropsType): JSX.Element {

	const resourceName = ResourceEnum.PURCHASE_PRICE_LIST.name;

	const [rows, setRows] = useState<RowType[]>([]);
	const [selectedId, setselectedId] = useState<number | null>(null);

	const [listPurchasePriceListSm, setListPurchasePriceListSm] = useState<ListPurchasePriceListSm>(new ListPurchasePriceListSm());

	const [showSd, setShowSd] = useState<boolean>(false);
	const [sdResolve, setSdResolve] = useState<any>(null);

	const [messageDialogMessage, setMessageDialogMessage] = useState("");
	const [showMessageDialog, setShowMessageDialog] = useState(false);

	const confirm = useConfirm();

	const colNames = [
		"Name",
	];

	useEffect(() => {
		async function process() {
			loadData(listPurchasePriceListSm);
		}
		process();
	}, []);

	const handleClickAdd = () => {
		openAddView(menu);
	};

	const handleClickEdit = () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}
	};

	const handleClickDelete = async () => {
		if (selectedId === null) {
			setMessageDialogMessage("Select atleast one row");
			setShowMessageDialog(true);
			return;
		}

		let choice = await confirm!({
			message: "Are you sure you want to delete?",
			title: "Delete confirmation"
		});

		if (choice === undefined || choice === null || choice === false)
			return;

		const requestOptions = {
			method: "DELETE",
			headers: {
				"Authorization": "Bearer " + TOKEN.val,
				"Content-Type": "application/json"
			}
		}

		const res = await fetch(strFormat("%s/%s/%s", BASE_URL, resourceName, selectedId), requestOptions);

		if (res.status !== 204) {
			const data = await res.json();
			setMessageDialogMessage(data.message);
			setShowMessageDialog(true);
		}
		else {
			loadData(listPurchasePriceListSm);
		}
	};

	const handleClickExport = () => {
		console.log("handleClickExport");
	};

	const handleClickPrint = () => {
		console.log("handleClickPrint");
	};

	const handleClickOpen = () => {
		console.log("handleClickOpen");
	};

	const handleClickFilter = () => {
		console.log("handleClickFilter");
	};

	const handleClickClearFilter = () => {
		console.log("handleClickClearFilter");
	};

	const handleClickColSettings = () => {
		console.log("handleClickColSettings");
	};

	const loadData = async (
		listPurchasePriceListSm: ListPurchasePriceListSm,
		colSettingsLocal?: ColSettingResType[]
	) => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Bearer " + TOKEN.val,
				"Content-Type": "application/json",
			},
		};
		const res = await fetch(strFormat("%s/%s", BASE_URL, resourceName)
			//+ new URLSearchParams(listVouOthChargeSm.toUrlSearchParams())
			+ getQueryString(listPurchasePriceListSm)
			, requestOptions);
		const results: ListPurchasePriceListResType[] = await res.json();

		let dataToReturn: RowType[] = [];
		results.forEach((result) => {
			let oneRowArray: string[] = [];

			oneRowArray.push(result.name);

			dataToReturn.push({
				id: result.id,
				data: oneRowArray
			});
		});

		setRows(dataToReturn);
	}

	return (
		<>
			<ListViewButtonBar
				showAdd={true}
				showEdit={true}
				showDelete={true}
				showExport={true}
				showPrint={false}
				showOpen={false}
				showOptions={false}
				handleClickAdd={handleClickAdd}
				handleClickEdit={handleClickEdit}
				handleClickDelete={handleClickDelete}
				handleClickExport={handleClickExport}
				handleClickPrint={handleClickPrint}
				handleClickOpen={handleClickOpen}
				handleClickFilter={handleClickFilter}
				handleClickClearFilter={handleClickClearFilter}
				handleClickColSettings={handleClickColSettings}
			/>
			{rows.length > 0 ? (
				<ListViewTable
					colNames={colNames}
					rows={rows}
					setSelectedId={setselectedId}
				/>
			) : null}

			<MessageDialog
				showDialog={showMessageDialog}
				modalMessage={messageDialogMessage}
				setShowDialog={setShowMessageDialog} />
		</>
	);
}

export default ListPurchasePriceList;
